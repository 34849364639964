

body.page-dashboard {


    .card {

        border: 0 {
            radius: 0;
        }


        .card-header {
            margin-bottom: 15px;
            border-bottom: 1px solid $c-gray-lighter;


            .card-title {
                overflow: visible;
            }

        }

    }

}