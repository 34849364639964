

.uk-label {
    //padding: 3px 10px;
    border-radius: 3px;

    min-height: 25px;
    line-height: 30px;

    font: {
        size: 12px;
        //weight: 700;
    }
    letter-spacing: 1px;

/*    display: flex;
    align-items: center;
    justify-content: center;*/
}