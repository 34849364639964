.#{$namespace}btn-timetracker {
    position: static;
    margin-right: 20px;
    height: 60px;

    //border: 1px solid $c-gray-light {
    //    radius: 3px;
    //}

    border: 1px solid $c-gray-light{
        top: 0;
        bottom: 0;
    }
    //background-color: $c-gray-lighter-alt-secondary;
    padding: 5px 15px 5px 15px;

   /* &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: -25%;
        left: -5px;

        display: block;
        width: 1px;

        background-color: red;
    }*/

    #prestation-timer {
        position: relative;
        display: inline-block;
        width: 35px;
        height: 35px;

        margin: 0 0 0 15px;

        background-color: $c-white;
        border: 1px solid $c-gray-light;
        border-radius: 3px;

        text-align: center;
        line-height: 35px;

        &:hover {
            background-color: $c-btn-default-background-hover;
            border-color: $c-btn-default-background-hover;
            color: $c-btn-default-color-hover;
        }

        .pg-close {
            display: none;
            visibility: hidden;

            position: absolute;
            top: 50%;
            left: 50%;

            font-size: 24px;

            transform: translate(-50%, -50%);
        }

        &.uk-open {
            .timetracker-icon {
                display: none;
                visibility: hidden;
            }

            .pg-close {
                display: inline-block;
                visibility: visible;
            }
        }

    }

    &--label {
        overflow: hidden;
        max-width: 175px;

        font: {
            size: 12px;
            weight: normal;
        }
        line-height: normal;
        text-overflow: ellipsis;
        white-space: nowrap;

    }

    &--timer {
        padding-top: 2px;
        font: {
            size: 15px;
            weight: 700;
        }
        letter-spacing: 1px;
        line-height: 15px;
    }
}

.#{$namespace}timetracker-dropdown {
    &.uk-dropdown {
        //max-width: 375px;
        width: 375px;

        //border: 1px solid $c-gray-light;
        background-color: $c-gray-lighter;
        padding: 15px 15px 25px 15px;
    }

    .select2-selection__rendered {
        white-space: nowrap;
        max-width: 250px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &--title {
        display: none;
        visibility: hidden;

        /*padding-right: 30px;

        font: {
            size: 18px;
            weight: bold;
        }
        line-height: 30px;*/
    }
    .form-group + .#{$namespace}actions {
        margin-top: -5px;
    }

    .#{$namespace}actions {
        .uk-button {
            height: 32px;

            border-radius: 16px;
            padding: 0 15px;

            font: {
                size: 13px;
            }
            text-transform: none;
            line-height: 32px;
        }

        /*.uk-first-column {
            margin-top: 0;
        }*/

        .#{$namespace}btn-or {
            display: inline-block;
            height: 32px;

            padding: 0 10px;

            line-height: 32px;
        }
    }

    &--btn-close {
        display: none;
        visibility: hidden;

        /*position: absolute;
        top: 15px;
        right: 15px;

        .btn:not(.dropdown-toggle).btn-xs {
            box-sizing: border-box;
            width: 26px;
            height: 26px;
            padding: 0;
            min-width: auto;
            line-height: normal;

            border-radius: 26px;


            i.pg-close {
                font-size: 20px;
            }
        }*/
    }

    .form-group label,
    .form-group label:not(.error)
    {
        padding-top: 0;
        font-size: 11px;
        line-height: 16px;
    }

    &--last-prestation {
        position: relative;
        margin: 25px -15px -25px -15px;
        background-color: $c-white;
        border-top: 1px solid $c-gray-light;
        padding: 25px 15px 10px 15px;

        font: {
            size: 11px;
        }

        &--title {
            position: absolute;
            top: 0;
            left: 50%; //15px;

            display: inline-block;
            height: 22px;

            padding: 0 10px;
            background-color: $c-white;
            border: 1px solid $c-gray-light;
            border-radius: 10px;

            font: {
                size: 12px;
                weight: normal; //bold;
            }
            line-height: 22px;

            transform: translate(-50%, -50%);
        }

        &--project-name {
            white-space: nowrap;
            max-width: 225px;
            overflow: hidden;
            text-overflow: ellipsis;

            cursor: default;
        }

        &--time {
            text-align: center; //right;
            font: {
                size: 26px;
                weight: bold;
            }
            line-height: normal;

            small {
                display: block;
                font: {
                    size: 11px;
                    weight: normal;
                }
                line-height: 11px;
            }
        }

    }
}

