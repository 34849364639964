

.quickview-wrapper {

    right: -400px;
    width: 400px;
    padding-bottom: 60px;

    #quickview-alerts {
        padding-bottom: 60px !important;
    }

    .list-view-group-header,
    .list-view-fake-header
    {
        font-family: $f-body;
        text-transform: uppercase; //none;
    }

    .nav.nav-tabs {
        padding: 0 43px 0 5px;
        background-color: $c-black;
        color: $c-white;

        & > li {

            & > a {
                color: $c-white !important;
                text-transform: uppercase; //none;
            }

        }
    }

    .alert-list {

        &:hover {
            background-color: $c-gray-light;
        }

        & > a {
            height: auto;

            div {
                line-height: normal;
            }

            .text-master {
                color: $c-black !important;
            }
        }
    }

    .btn-link.quickview-toggle {
        color: $c-white;

        &:hover {
            cursor: pointer;
        }

        & > i{
            color: $c-white;
        }

    }
}
