/* ================================ */
/* Utilities
/* ================================ */
/*%clearfixe::after {
	visibility: hidden;
	display: block;
	font-size: 0;
	content: " ";
	clear: both;
	height: 0;
}*/

/* Vertical alignment
 ========================================================================== */
/*
 * Remove whitespace between child elements when using `inline-block`
 */
%vertical-align {
	font-size: 0.001px;
	&:before {
		content: '';
		display: inline-block;
		height: 100%;
		vertical-align: middle;
	}
}
%vertical-align-common {
	display: inline-block;
	max-width: 100%;
	font-size: 1rem;
}
%vertical-align-middle {
	@extend %vertical-align-common;
	vertical-align: middle;
}
%vertical-align-bottom {
	@extend %vertical-align-common;
	vertical-align: bottom;
}



/* Constant ratio
 ========================================================================== */

%constant-width-to-height-ratio {

	&::before {
		content: '';
		display: block;
		padding-top: 100%;
		float: left;
	}

	&::after {
		content: '';
		display: block;
		clear: both;
	}
}

/* ================================ */
/* CONTAINERS
/* ================================ */

%container {
	padding-left: 10px;
	padding-right: 10px;
}
%container-1920 {
	@extend %container;
	width: 100%;
	max-width: 1920px;
	margin: 0 auto;
}
%container-1600 {
	@extend %container;
	width: 100%;
	max-width: 1600px;
	margin: 0 auto;
}
%container-1400 {
	@extend %container;
	width: 100%;
	max-width: 1400px;
	margin: 0 auto;
}
%container-1200 {
	@extend %container;
	width: 100%;
	max-width: 1200px;
	margin: 0 auto;
}
%container-1170 {
	@extend %container;
	width: 100%;
	max-width: 1170px;
	margin: 0 auto;
}
%container-1024 {
	@extend %container;
	width: 100%;
	max-width: 1024px;
	margin: 0 auto;
}
%container-960 {
	@extend %container;
	width: 100%;
	max-width: 960px;
	margin: 0 auto;
}
%container-800 {
	@extend %container;
	width: 100%;
	max-width: 800px;
	margin: 0 auto;
}
%container-600 {
	@extend %container;
	width: 100%;
	max-width: 600px;
	margin: 0 auto;
}