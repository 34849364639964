

body {
    letter-spacing: normal;
    font-feature-settings: initial;

    background-color: #F4F4F4;
    font: {
        family: $f-body !important;
    }

    ::selection {
        background: $c-selection-default-background; //$c-beige;
        color: $c-selection-default-color; // $c-white;
    }
    ::-moz-selection {
        background: $c-selection-default-background; //$c-beige;
        color: $c-selection-default-color; // $c-white;
    }
}


a {
    color: $c-link;

    &:hover {
        color: $c-link-hover;
        //text-decoration: underline;
    }

    &:active,
    &:focus
    {
        color: $c-link-active;
    }
}


/* Page Loader
------------------------------------
*/
.pace{
    .pace-progress{
        background: $c-page-progress; //$c-beige;
        height: 3px;
    }
}