

.copyright {
    a {
        &:hover {
            text-decoration: underline;
        }
    }

    .#{$namespace}app-logo {

        img {
            margin: 5px 0 10px 0;
            height: 25px;
            opacity: 0.2;
        }

        @include min-screen($pages-bp-large) {
            display: none;
            visibility: hidden;
        }
    }
}