

body.page-project {

    .#{$namespace}project {

        padding: 0; //15px 0 0 0;

        .nav.nav-tabs.nav-tabs-left {
            max-width: 250px;

            & > li > a {
                //padding: 10px 10px 10px 0;
                font: {
                    size: 10px;
                }
            }
        }

        &-media {
            padding: 0 !important; //7px;
            border: 1px solid $c-gray-lighter;

            min-height: 250px;
        }

        &-picture {
            position: relative;
            margin:0;

            background: {
                repeat: no-repeat;
                position: center;
                size: cover;
            }

            &-overlay {
                position: absolute;
                top: auto;
                right: 0;
                bottom: 0;
                left: 0;

                height: auto;
                padding: 80px 20px 5px 20px;

                text-align: right;
                color: $c-white;


                background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%);
                background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.8) 100%);
                background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.8) 100%);

                .project-percent {
                    font-size: 14px;
                }
            }
        }

        &-informations {

            .card {
                margin-bottom: 0;

                .card-block {
                   padding: {
                       top: 0;
                       right: 0;
                   }

                    @include max-screen($pages-bp-medium) {
                        padding: 0;
                    }
                }
            }

            h3 {
                font: {
                    family: $base-font-family-bold; //$base-font-family;
                }
                letter-spacing: -1px;
            }

            h5 {
               padding-left: 5px;
            }
        }

        &-address {
            //padding-left: 20px;
        }

        &-metas {
            padding: 25px 0 15px 0;

            ul {
                list-style: none;
                margin: 0;
                padding: 0;

                li {
                    list-style: none;

                    font: {
                        size: 12px;
                    }
                    line-height: 1.5em;
                    color: $c-gray;
                }

            }
        }

        .last-record {
            position: relative;
            display: block;
            width: 100%;
            text-align: left;

            //background-color: $c-white;
            //border: 1px solid $c-gray;

            //padding: 10px 15px;

            //transition: all 0.25s;

            .record-date {
                padding-right: 10px;

                font-weight: bold;
                //color: $c-gray;

                transition: all 0.25s;
            }

            .record-title {
                //color: $c-gray;

                transition: all 0.25s;
            }

            i {
                position: absolute;
                top: 50%;
                right: 15px;

                transform: translateY(-50%);
                color: $c-beige; //$c-gray;

                transition: all 0.25s;
            }

            &:hover {
                //border-color: $c-gray;
                //background-color: $c-gray;

                //color: $c-white;

                .record-date,
                .record-title
                {
                    color: $c-white;
                }

                i {
                    right: 5px;
                    color: $c-white;
                }
            }


        }

        &-link-delete {
            display: inline-block;
            float: right;

            margin: 0 10px;
            color: $c-red;
        }

        &-job {

            .card {
                .card-header {
                    position: relative;
                    margin-bottom: 0;
                    border-bottom: 0;
                    padding: {
                        top: 10px;
                        bottom: 0px;
                    }

                    .card-title {
                        font: {
                            family: $base-font-family-light;
                            size: 13px;
                            weight: normal; //600;
                        }
                    }
                }
            }

            &-order-actions {
                position: absolute;
                box-sizing: border-box;
                top: -1px;
                left: -34px;
                border: 1px solid $c-gray-lighter;

                a,
                button
                {
                    display: block;
                    width: 32px;
                    height: 32px;
                    box-sizing: border-box;

                    background-color: $c-white;

                    padding: 0;
                    text-align: center;
                    line-height: 32px;

                    color: $c-beige;
                    opacity: 1;

                    i {
                        color: $c-beige;
                    }

                    &:hover {
                        background-color: $c-beige;
                        color: $c-white;

                        i {
                            color: $c-white;
                        }
                    }

                    & + a,button {
                        border-top: 1px solid $c-gray-lighter;
                    }

                    &.is-disabled {
                        opacity: 0.4;

                        cursor: not-allowed;

                        i {
                            color: $c-gray;
                        }
                    }
                }

            }


            .#{$namespace}job{

                &-title {
                    display: inline-block;
                    width: 50%;
                    font: {
                        size: 13px;
                        weight: bold;
                    }


                    small {
                        display: inline-block;
                        visibility: hidden;
                        margin-left: 15px;

                        font: {
                            size: 11px;
                        }
                        color: $c-gray-light-alt;
                        text-transform: none;

                        @include max-screen($pages-bp-medium) {
                            margin-left: 0;
                            visibility: visible;

                            font: {
                                size: 10px;
                            }
                        }
                    }

                    .form-group {
                        margin: 0
                    }
                }

                &-percent {
                    display: inline-block;
                    width: 10%;

                    font-size: 13px;
                    text-align: center;
                }

                &-progress {
                    @extend %vertical-align;
                    display: inline-block;
                    width: 30%;
                    height: 100%;


                    .progress {
                        @extend %vertical-align-middle;
                        //margin: 10px 0;
                        display: block;
                        margin-bottom: 4px; //0;
                        border-radius: 2px;
                        overflow: hidden;

                        &-bar {
                            display: block;
                        }
                    }
                }

            }

            .card-header {
                //padding-left: 40px;

                @include max-screen($pages-bp-medium) {
                    padding: {
                        left: 10px;
                        right: 10px;
                    }
                }

                a[data-toggle='collapse']:not(.collapsed) {
                    .#{$namespace}job-title {
                        small {
                            //display: inline-block;
                            visibility: visible;
                        }
                    }
                }

                &:hover {
                    .#{$namespace}job-title {
                        small {
                            //display: inline-block;
                            visibility: visible;
                        }
                    }
                }
            }

            body.is-admin .card-block {
                padding-top: 0;
            }


            &-actions {
                display: block;
                padding: {
                    top: 5px;
                    bottom: 25px;
                }
            }


        }



        &-tasks {
            list-style: none;
            padding: 0;
            margin: 0;
        }

        &-task {
            list-style: none;
            padding: 10px 10px;
            margin: 0;
            //line-height: normal;

            border-bottom: 1px solid $c-gray-lighter;
            vertical-align: middle;

            @include max-screen($pages-bp-medium) {
                padding: 5px 0;
            }

            &:nth-last-child(1){
                border-bottom: 0;
            }

            .#{$namespace}task {

                &-title {
                    display: inline-block;
                    width: 50%;
                    font: {
                        size: 15px;
                        //weight: bold;
                    }
                    //color: $c-gray-dark;

                    @include max-screen($pages-bp-medium) {
                        width: 85%;
                    }

                    .user-type-initial {
                        display: inline-flex;

                        .thumbnail-wrapper {
                            max-width: 24px;
                            height: 24px;
                            margin-right: 5px;
                        }
                    }

                    .#{$namespace}sidebar-link {
                        display: inline-block;
                        margin-left: 20px;
                        font: {
                            size: 12px;
                            weight: normal;
                        }
                    }
                }

                &-dates {
                    padding: 5px 5px 0 5px;

                    &-label {
                        min-height: 32px;

                        font: {
                            size: 11px;
                        }
                        line-height: 34px;
                        color: $c-gray-light-alt;
                        text-transform: uppercase;
                        text-align: right;
                        letter-spacing: 0.06em;
                    }
                }

                &-status {
                    display: inline-block;
                    width: 10%;
                    height: 100%;


                    text-align: center;
                    line-height: normal;
                    vertical-align: middle;

                    .checkbox {
                        margin-top: -5px;
                        //vertical-align: top;
                    }

                    .project-status {
                        width: auto; //11px;
                        height: auto; //11px;
                        background: none;
                        font-size: 18px;
                        color: $c-gray-light;


                        &.is-completed {
                            color: $c-green;
                        }
                    }
                }

                &-date {
                    display: inline-block;
                    font: {
                        size: 11px;
                    }
                    color: $c-gray-light-alt;
                    vertical-align: middle;

                    @include max-screen($pages-bp-medium) {
                        display: block;
                    }

                }

                &-actions {
                    float: right;
                }

            }

            .radio,
            .checkbox
            {
                margin: {
                    top: 0;
                    bottom: 0;
                }
            }

            &-actions {
                padding-top: 15px;
            }
        }

        .dd {
            position: relative;
            display: block;
            //margin: 0;
            //padding: 0;
            max-width: inherit;
            //ist-style: none;
            font-size: inherit;
            line-height: inherit;
            float: none;
            width: 100%;
        }

    }



    .dd-item,
    .dd-empty,
    .dd-placeholder
    {
        display: block;
        position: relative;
        //margin: 0;
        //padding: 0;
        min-height: inherit;
        font-size: inherit;
        line-height: inherit;

        padding-left: 35px;
    }

    .dd-handle.dd3-handle {
        top: 7px;
        background: transparent; //$c-gray-light;
        //color: $c-beige;

        border: {
            radius: 0;
            width: 0;
        }
        box-shadow: none;
        text-align: center;
        padding: 0;

        width: 30px;
        height: 30px;
        line-height: 30px;

        box-sizing: border-box;
        cursor: move;

        span {
            display: block;
        }


        &::before {
            content: "";
            width: 30px;
            height: 30px;
            top: auto;
            background: {
                image: url('#{$img-path}design/icon-drag.png');
                position: center;
                repeat: no-repeat;
            }

            transition: all 0.15s;

        }

        &:hover {
            background: none;
            box-shadow: none;
            &::before {
                transform: scale(1.15);
                //opacity: 0.8;
            }
        }
    }

    &.is-admin {

       .#{$namespace}project {

           /*&-job {
               padding-left: 32px;
           }*/


           &-task {
               .#{$namespace}task-status{
                   //vertical-align: top;
                   //padding-top: 10px;
               }

               .#{$namespace}task-date {
                   //vertical-align: top;
                   //padding-top: 10px;
               }
           }

       }

    }

    #project-permissions-group {
      .form-check {
        display: inline-block;
        margin-right: 20px;

        &:last-child {
          margin-right: 0;
        }

        input.permissions {
          position: relative;
          margin-top: 0;
          margin-left: 0;
          margin-right: 5px;
        }

        label {
          padding-left: 0;
        }
      }
    }
}





