%animated {
    animation-duration: 1s;
    animation-fill-mode: both;
}

%animated-infinite {
    animation-iteration-count: infinite;
}

%animated-delay-1s {
    animation-delay: 1s;
}

%animated-delay-2s {
    animation-delay: 2s;
}

%animated-delay-3s {
    animation-delay: 3s;
}

%animated-delay-4s {
    animation-delay: 4s;
}

%animated-delay-5s {
    animation-delay: 5s;
}

%animated-fast {
    animation-duration: 800ms;
}

%animated-faster {
    animation-duration: 500ms;
}

%animated-slow {
    animation-duration: 2s;
}

%animated-slower {
    animation-duration: 3s;
}

@media (prefers-reduced-motion) {
    %animated {
        animation: unset !important;
        transition: none !important;
    }
}

// -------------------------------------------------------------
// -------------------------------------------------------------
@-webkit-keyframes swing {
    20% {
        -webkit-transform: rotate3d(0, 0, 1, 15deg);
        transform: rotate3d(0, 0, 1, 15deg);
    }

    40% {
        -webkit-transform: rotate3d(0, 0, 1, -10deg);
        transform: rotate3d(0, 0, 1, -10deg);
    }

    60% {
        -webkit-transform: rotate3d(0, 0, 1, 5deg);
        transform: rotate3d(0, 0, 1, 5deg);
    }

    80% {
        -webkit-transform: rotate3d(0, 0, 1, -5deg);
        transform: rotate3d(0, 0, 1, -5deg);
    }

    to {
        -webkit-transform: rotate3d(0, 0, 1, 0deg);
        transform: rotate3d(0, 0, 1, 0deg);
    }
}

@keyframes swing {
    20% {
        -webkit-transform: rotate3d(0, 0, 1, 15deg);
        transform: rotate3d(0, 0, 1, 15deg);
    }

    40% {
        -webkit-transform: rotate3d(0, 0, 1, -10deg);
        transform: rotate3d(0, 0, 1, -10deg);
    }

    60% {
        -webkit-transform: rotate3d(0, 0, 1, 5deg);
        transform: rotate3d(0, 0, 1, 5deg);
    }

    80% {
        -webkit-transform: rotate3d(0, 0, 1, -5deg);
        transform: rotate3d(0, 0, 1, -5deg);
    }

    to {
        -webkit-transform: rotate3d(0, 0, 1, 0deg);
        transform: rotate3d(0, 0, 1, 0deg);
    }
}

.animate-swing,
%animate-swing
{
    @extend %animated;
    -webkit-transform-origin: top center;
    transform-origin: top center;
    -webkit-animation-name: swing;
    animation-name: swing;
}

.highlight {
    background-color: $c-animation-highlight-background;

    td {
        background-color: $c-animation-highlight-background !important;
    }
}

@keyframes highlight {
    0% {
        //background-color: inherit;
        color: inherit;
    }
    25% {
        background-color: $c-animation-highlight-background;
    }
    100% {
        //background-color: transparent;
        color: inherit;
    }
}


.animate-highlight,
%animate-highlight
{
    @extend %animated;
    -webkit-animation-name: highlight;
    animation-name: highlight;
    animation-duration: 3000ms;

}

tr.animate-highlight {
    & > td {
        @extend %animated;
        -webkit-animation-name: highlight;
        animation-name: highlight;
        animation-duration: 3000ms;
    }
}
