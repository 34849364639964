
body.page-planning {


    .#{$namespace}progress-planning {
        padding-bottom: 35px;

        .start-date,
        .end-date
        {
            display: inline-block;
            float: left;
            width: 50%;

            font: {
                size: 15px;
                weight: bold;
            }
            small {
                display: block;
                font: {
                    size: 11px;
                    weight: normal;
                }
                color: $c-gray-light-alt;
            }
        }

        .end-date {
            text-align: right;
        }

        .progress {
            clear: both;
            overflow: visible;
            height: 15px;
            border-radius: 3px;

            .progress-bar {
                position: relative;
                overflow: visible;
                border-bottom-left-radius: 3px;
                border-top-left-radius: 3px;
                height: 15px;

                .today {
                    position: absolute;
                    width: 75px;
                    bottom: -45px;
                    right: -(75px / 2);
                    color: $c-planning-today-color; //$c-beige;
                    text-align: center;


                    font: {
                        size: 13px;
                        weight: bold;
                    }

                    small {
                        display: block;
                        font: {
                            size: 10px;
                            weight: normal;
                        }
                        color: $c-gray-light-alt;
                    }

                    &::before {
                        content: "";
                        position: absolute;
                        left: calc(50% - 1px);
                        top: -15px;

                        display: block;
                        width: 1px;
                        height: 15px;

                        background-color: $c-progress-bar; //$c-beige;
                    }
                }
            }

            #current-progress {
                font: {
                    size: 11px;
                    weight: bold;
                }
            }
        }
    }

    #container-planning {
        min-width: 300px;
        width: 100%;
        margin: 1em auto;

        @include max-screen($pages-bp-small) {
            display: none;
            visibility: hidden;
        }
    }

    #container-small-screen-planning {
        @include min-screen($pages-bp-small) {
            display: none;
            visibility: hidden;
        }

        .#{$namespace}planning-tasks {
            list-style: none;
            margin: 25px 0;
            padding: 0;
        }

        .#{$namespace}planning-task {
            display: block;
            padding: 15px 0;

            & + .#{$namespace}planning-task {
                border-top: 1px solid $c-gray-light;
            }

            &-title {
                display: block;
            }

            &-date {
                display: block;
            }

            &-today {
                position: relative;
                border-top: 2px solid $c-beige;
                color: $c-beige;

              /*  & + .#{$namespace}planning-task {
                    border-top: 0 none;
                }*/

                &::before {
                    content: '';
                    position: absolute;
                    top: -1px;
                    left: -10px;

                    display: block;
                    width: 10px;
                    height: 10px;

                    border-radius: 50%;
                    background-color: $c-beige;

                    transform: translateY(-50%);
                }

                span {
                    position: absolute;
                    top: 0;
                    right: 0;

                    display: inline-block;
                    padding-left: 5px;

                    background-color: $c-white;

                    transform: translateY(-50%);
                }
            }
        }
    }


    .highcharts-menu-item {
        &:hover {
            background-color: $c-dropdown-hover !important;
        }
    }

    .rp-planning-disclaimer {
        font-size: 70%;
    }

}
