

.progress-bar {
    height: 100%;
    border-radius: 2px;

    &-default {
        background-color: $c-progress-bar;
        background-image: none;
    }
}
