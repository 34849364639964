
body.is-admin {

    /*&.horizontal-app-menu.fixed-header {
        overflow: scroll;
    }*/
    &.horizontal-app-menu {
        .card-header {
            & + .menu-bar,
            & + .#{$namespace}menu-project-container
            {
                margin: {
                    top: 15px;
                    bottom: 0; //35px;
                }

                @include max-screen($uk-bp-medium) {
                    margin: {
                        top: 5px;
                        bottom: 5px;
                    }
                }

                & + * {
                    margin-top: 0;
                }
            }
        }
    }

    &.horizontal-app-menu .menu-bar {
        &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;

            display: block;
            height: 2px;
            width: 100%;

            background-color: rgba($c-gray-lighter-alt, 0.25);
        }
        & > ul > li {
            &.active {
                border-bottom-color: $c-black !important; //#788195!important;
            }

            &:hover {
                border-bottom-color: $c-black; //#788195;
            }

            & > a {
                color: $c-black !important; //#788195 !important;

                &[href='#'] {
                    color: rgba($c-black, 0.5) !important; //rgba($c-gray-lighter-alt, 0.5) !important;
                }
            }
        }
    }


    &.horizontal-app-menu .secondary-sidebar {
        margin: 0;
        overflow-y: auto;
    }

    &.horizontal-app-menu.fixed-header .page-container {
        overflow: initial;
    }

    &.horizontal-app-menu .header,
    .header
    {

        .rp-username > a {
            color: $c-link;
            &:hover {
                color: $c-link-hover;
            }
        }
        .header-icon,
        .fa-bell,
        .fa-gear
        {
            font-size: 20px;
            color: $c-link-contrast;

            @include min-screen($pages-bp-large) {
                color: $c-link;
            }

            &:hover {
                color: $c-link-hover-contrast;

                @include min-screen($pages-bp-large) {
                    color: $c-link-hover;
                }
            }

            svg {
                display: inline;
            }
        }
    }




    .secondary-sidebar {
        display: none;
        background-color: $c-black-alt;
        padding-top: 0;

        @include min-screen($pages-bp-large) {
            position: fixed;
            display: block;
        }

        .title-bar {
            background-color: $c-black; /*fallback for older browser*/
            background-color: var(--main-header-bg-color);
            padding: 15px 20px; //0 20px;
            height: auto; //60px;
            //max-height: 60px;
            box-sizing: border-box;

            .brand.inline {
                //padding-right: 10px;
                //border-right: 1px solid $c-white;

                img {
                    width: auto; //35px;
                    max-height: 75px;
                }

                @include max-screen($pages-bp-large) {
                    position: absolute;
                    left: 50%;
                    top: 50%;

                    border: 0 !important;
                    padding: 0 !important;

                    transform: translate(-50%, -50%);

                }

            }
            .page-title {
                position: relative;
                display: inline-block;
                box-sizing: border-box;
                top: 5px;
                padding-left: 10px;
                //padding-bottom: 5px;
                color: $c-white;

                font: {
                    size: 21px;
                    weight: normal;
                }
                line-height: 21px;

                @include max-screen($pages-bp-large) {
                    display: none;
                }
            }

        }

        .menu-title {
            padding-left: 20px;
        }

        .main-menu {
            margin-top: 0px;
            padding: {
                top: 30px;
                bottom: 30px;
            }
        }

        ul li a {
            padding: 10px 20px;
            border-left: 4px solid transparent;

            &:hover {
                background: rgba($c-white, 0.2);

                .#{$namespace}icon-thumbnail {
                    img, svg {
                        opacity: 1;
                    }
                }

                .#{$namespace}title {
                    opacity: 1;
                }
                .#{$namespace}quick-info {
                    opacity: 0.8;
                }
            }
        }

        ul li.is-active {
            a {
                background: rgba($c-black, 0.5); //rgba($c-white, 0.1);
                border-left-color: $c-white;

                .#{$namespace}icon-thumbnail {
                    img, svg {
                        opacity: 1;
                    }
                }

                .#{$namespace}title {
                    opacity: 1;
                }
                .#{$namespace}quick-info {
                    opacity: 0.8;
                }
            }
        }


        .#{$namespace}icon-thumbnail {
            display: flex;
            height: auto;
            width: auto;

            //line-height: 40px;
            text-align: center;
            vertical-align: top;
            position: relative;
            left: 0;
            float: left;
            margin-right: 14px;
            //color: #788195;
            font-size: 16px;

            img, svg {
                //margin-top: 5px;
                width: 30px; //26px;
                height: 30px; //26px;
                opacity: 0.5;
                color: $c-white;
            }
        }

        .#{$namespace}title {
            //display: inline-block;
            line-height: normal;
            padding: {
                top: 10px;
                bottom: 10px;
            }
            color: $c-white;
            opacity: 0.5;
        }

        .#{$namespace}quick-info {
            display: block;
            font: {
                size: 11px;
            }
        }

    }

    .main-frame {
        overflow-y: auto;
        box-sizing: border-box;

        @include min-screen($pages-bp-large) {
            padding-left: 250px;
        }

        .container {
            width: auto;
        }

        .content {
            position: relative;
            padding: {
                right: 20px;
                left: 20px;
            }
        }
    }
    
    .user-type-initial {
        display: inline-block;
        z-index: 1;

        min-width: 70px; //35px;

        font: {
            size: 18px;
            weight: 700;
        }
        letter-spacing: 2px;
        text-transform: uppercase;
        cursor: default;

    }
}
