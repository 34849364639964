#feedback-btn {
    position: fixed;
    bottom: 25px;
    left: 25px;
    z-index: 1000;
    background: $c-pluriell-red;
    color: $c-white;
    border-color: $c-pluriell-red;

    @include max-screen($bt-bp-small) {
        padding: 0 10px;
    }

    svg {
       // margin-right: 5px;
    }

    span {
        display: none;
        visibility: hidden;

        @include min-screen($bt-bp-small) {
            display: inline-block;
            visibility: visible;
            margin-left: 5px;
        }
    }
}
