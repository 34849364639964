/*
* @Project: Pluriell
* @Version: 1
* @Release Date: 2018 | 2019 | 2020
* @author: Bastien Heynderickx (hachbé)
*
*/

// Mixins and variables
@import "r-plus/_mixins.scss";
@import "r-plus/_variables.scss";
@import "r-plus/_placeholders.scss";
@import "r-plus/_animations.scss";
@import "r-plus/_helpers.scss";

// Elements
@import "r-plus/elements/_base.scss";
@import "r-plus/elements/_alert.scss";
@import "r-plus/elements/_buttons.scss";
@import "r-plus/elements/_headings.scss";
@import "r-plus/elements/_progress-bar.scss";
@import "r-plus/elements/_forms.scss";
@import "r-plus/elements/_table.scss";
@import "r-plus/elements/_label.scss";
@import "r-plus/elements/_links.scss";
@import "r-plus/elements/_loader.scss";
@import "r-plus/elements/_iframe.scss";
@import "r-plus/elements/_icons.scss";
@import "r-plus/elements/_helptips.scss";

// Modules
@import "r-plus/modules/_layout.scss";
@import "r-plus/modules/_project-status.scss";
@import "r-plus/modules/_header.scss";
//@import "r-plus/modules/_breadcrumb.scss"; // Not used yet
@import "r-plus/modules/_card.scss";
@import "r-plus/modules/_modal.scss";
@import "r-plus/modules/_widgets.scss";
@import "r-plus/modules/_tabs.scss";
@import "r-plus/modules/_copyright.scss";
@import "r-plus/modules/_report.scss";
@import "r-plus/modules/_quickview.scss";
@import "r-plus/modules/_collapse.scss";
@import "r-plus/modules/_datepicker.scss";
@import "r-plus/modules/_dropdown.scss";
@import "r-plus/modules/_timeline.scss";
@import "r-plus/modules/_comments.scss";
@import "r-plus/modules/_sidebar.scss";
@import "r-plus/modules/_projects-grid.scss";
@import "r-plus/modules/_documents.scss";
@import "r-plus/modules/_filters-bar.scss";
@import "r-plus/modules/_app-feedback.scss";
@import "r-plus/modules/_switcher.scss";
@import "r-plus/modules/_timetracker.scss";
@import "r-plus/modules/_gantt.scss";
@import "r-plus/modules/_construction-task.scss";

// Components

// Overwrites
@import "r-plus/overwrites/uikit/_alert.scss";
@import "r-plus/overwrites/uikit/_accordion.scss";
@import "r-plus/overwrites/uikit/_label.scss";
@import "r-plus/overwrites/uikit/_notification.scss";
@import "r-plus/overwrites/uikit/_modal.scss";
@import "r-plus/overwrites/uikit/_dropdown.scss";
@import "r-plus/overwrites/uikit/_search.scss";
@import "r-plus/overwrites/uikit/_offcanvas.scss";
@import "r-plus/overwrites/uikit/_tab.scss";

@import "r-plus/overwrites/painterro/_module.scss";

// Pages
@import "r-plus/pages/_page-dashboard.scss";
@import "r-plus/pages/_page-project.scss";
@import "r-plus/pages/_page-reports.scss";
@import "r-plus/pages/_page-files.scss";
@import "r-plus/pages/_page-login.scss";
@import "r-plus/pages/_page-profile.scss";
@import "r-plus/pages/_page-planning.scss";
@import "r-plus/pages/_page-todo.scss";
@import "r-plus/pages/_page-history.scss";
@import "r-plus/pages/_page-time-tracker.scss";

// Layouts
@import "r-plus/layouts/_admin.scss";


// Vendor


/*.btn.btn-xs > i.fa {
  position: relative;
  top: -2px;
}*/

td:last-child {
    text-align: right;
}

.page-item.active .page-link {
    background-color: #636c72;
    border-color: #636c72;
    color: white !important;
}

/*.horizontal-app-menu .menu-bar > ul li > a {
  color: #FFFFFF !important;
}*/

button,
a,
input[type=submit] {
    cursor: pointer;
}

.vich-image {
    display: none;
}

@media only screen and (min-width: 768px) {
    .sm-gutter form .row {
        margin-left: 0;
        margin-right: 0;
    }
}

/*.dropdown-menu {
  z-index: 1060 !important;
}*/


.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
}

.lds-ellipsis div {
    position: absolute;
    top: 27px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: black;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
    left: 6px;
    animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
    left: 6px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
    left: 26px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
    left: 45px;
    animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}

@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(19px, 0);
    }
}

.sortable-task-handle,
.sortable-job-handle {
    cursor: grab;
    position: absolute;
    left: 0;
}

.sortable-job-handle {
    left: 15px;
    top: 30px;
}

/*
#select-template-button + .select2-container {
  width: 250px;
  box-sizing: border-box;
  font-family: "Helvetica Neue LT Std Md", Helvetica, Arial, sans-serif;
  font-size: 15px;
  font-weight: normal;
  padding: 0 20px;
  letter-spacing: 0.01em;
  border: 2px solid #000000;
  border-radius: 21px;
  height: 42px;
  min-width: 42px;
  line-height: 40px;
  color: #000000;
  background-color: #FFFFFF;
  margin-bottom: 0;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  position: relative;
  transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;

  .select2-selection {
    border: none !important;
    padding-top: 7px;
  }

  &.select2-container--open .select2-selection {
    background: transparent;
  }
}*/

.rp-comment-content {
    a {
        color: #9c9c9c;
    }
}

.dataTable {
    th > select,
    th > .select2-container {
        display: block;
    }
}


.form-group {
    .controls {
        .input-group-addon {
            border: none;
            background-color: transparent;
        }
    }
}

.rp-task-status .disabled,
input[type=checkbox][disabled] + label {
    cursor: default;
}

#advanced_report_nextVisitToConfirm.form-check-input {
    margin-left: 0;
}

#quickview-alerts .view-port {
    overflow-y: scroll;
}

.form-group legend, .form-group legend:not(.error) {
    margin: 0;
    padding: 5px 0 0;
    font-family: Helvetica Neue LT Std Md, Helvetica, Arial, sans-serif;
    font-size: 12px;
    font-weight: 400;
    color: #9c9c9c;
}


tr.dtrg-group {
    td {
        text-align: left;
        color: #000;
    }
}

.group-by {
    &.project {
        td.project {
            font-size: 0;
        }
    }

    &.user {
        td.user {
            font-size: 0;
        }
    }
}

#prestations-datatables-container .dataTables_empty {
  font-size: 0;
  line-height: 20px;
}

.datepicker-days {
  tbody .today:not(.active) {
    background-color: $c-gray-light !important;
    color: $c-gray !important;
  }

  tfoot .today {
    background-color: $c-black !important;
    color: $c-white !important;
  }
}

div.dataTables_wrapper div.dataTables_filter label {
  font-size: 0;
}

#project-list-search {
  border: none;
  background: transparent;
  padding: 20px;
}


#prestations-datatables-container,
#tasks-datatables-container,
#contact-datatables-container {
  .dataTables_filter {
    display: none;
  }
}

.sf-minitoolbar {
  z-index: 1000 !important;
}

.select2-results__option > .level-0 {
    font-weight: bold;
}

@for $i from 1 through 20 {
    .select2-results__option > .level-#{$i} {
        padding-left: #{$i * 10}px;
    }
}

.edit-construction-task-btn {
    cursor: pointer;
}

#construction-tasks .level-0 {
    font-weight: bold;
}

@for $i from 1 through 20 {
    #construction-tasks .level-#{$i} {
        padding-left: #{($i - 1) * 20}px;

        svg {
            position: relative;
            top: -5px;
        }
    }
}

.rp-width-xsmall {
    width: 80px;
}

#report-observation-status-container {
    .form-group {
        margin-right: 10px;
    }

    .form-check {
        margin-top: 30px;

        .form-check-input {
            margin-top: 7px;
            margin-left: 0;
        }
    }

    .delete-status-btn {
        margin-top: 20px;
    }

    .select2 {
        display: block;
    }
}

.color {
    width: 20px;
    display: inline-block;
    height: 20px;
    border-radius: 100%;
    vertical-align: middle;
}

.bg-red {
    background-color: #dc3a55;
}

.bg-orange {
    background-color: #fe9441;
}

.bg-yellow {
    background-color: #ffd02d;
}

.bg-violet {
    background-color: #7940a1;
}

.bg-navy-blue {
    background-color: #2b345d;
}

.bg-blue {
    background-color: #006fcb;
}

.bg-light-blue {
    background-color: #00b1fe;
}

.bg-light-green {
    background-color: #00d3bd;
}

.bg-green {
    background-color: #0ac37e;
}

.bg-light-grey {
    background-color: #939293;
}

.bg-brown {
    background-color: #965837;
}

.bg-black {
    background-color: #000000;
}

.select2-container--default .select2-results__option[aria-disabled=true] {
    display: none;
}

fieldset.form-group > .form-control.is-invalid {
    height: auto;
    background-color: transparent;
    border: none;
    padding: 5px 0;
}

form .alert-danger {
    background-color: transparent;
    padding: 0;
}

.invalid-feedback,
.alert-danger {
    .badge-danger {
        display: none;
    }

    .form-error-message {
        display: block;
        background-color: rgba(242,43,43,.1);
        color: #f22b2b;
        border-radius: 5px;
        padding: 10px 15px;
        border-left: 7px solid #f22b2b;
    }
}
