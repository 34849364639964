

.datepicker {
    thead tr {

        .next,
        .prev
        {
            color: $c-datepicker-next-prev; //$c-beige;

            &::before {
                color: $c-datepicker-next-prev; //$c-beige;
            }
        }

        .dow {
            color: $c-datepicker-dow; //$c-beige;
        }



    }

    tbody tr {
        td.active,
        td.selected
        {
            background-color: $c-datepicker-day-selected !important; //$c-beige !important;
        }
    }
}

