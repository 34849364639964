.uk-dropdown {
    padding: 15px;

    .uk-dropdown-nav {
        a,
        button
        {
            color: $c-link;
            display: block;
            i {
                margin-right: 10px;
            }

            &:hover {
                color: $c-link-hover;
            }
        }
        form {
            display: block;

        }
        button {
            width: 100%;
            background: transparent;
            border: 0 none;
            padding: 0;

        }

        .#{$namespace}delete-link {
            text-align: left;
            color: $color-danger;
        }
    }
}