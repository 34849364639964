
.project-status {
    display: inline-block;
    width: 9px;
    height: 9px;
    margin-left: 7px;

    border: none;
    border-radius: 50%;
    background: #48b0f7;

}