.#{$namespace}menu-project-container {
    position: relative;

    .btn-filters-toggle {
        @include min-screen($uk-bp-medium) {
            float: right;
        }
    }
}

.#{$namespace}filters-bar {
    margin: {
        top: 15px;
        bottom: 15px;
    }
    background: $c-white;
    border: 1px solid $c-gray-light;
    padding: 15px 25px;


    .select2-container {
        width: 100% !important;
    }


    &--sep-top {
        border: {
            top: 1px solid $c-gray-lighter;
        }
        padding: {
            top: 10px;
        }
    }
}
