
.form-group{
    position: relative;

    label, label:not(.error) {
        padding-top: 5px;
        margin-bottom: 0;
        font-family: $base-font-family;
        font: {
            size: 12px;
            weight: normal;
        }
        color: $c-gray-light-alt;
    }

    &:not(.form-group-default).required:after {
        color: $c-red-light; //#f55753;
        content: "*";
        font-family: Arial, Helvetica, sans-serif;
        font-size: 20px;
        position: absolute;
        right: 12px;
        top: 0px;
    }
}

.radio label,
.checkbox label
{
    width: 25px;
    height: 25px;

    &::before {
        width: 25px;
        height: 25px;
    }

    &::after {
        top: 3px;
        left: 5px;
        font-size: 16px;
    }
}

.checkbox.check-success input[type=checkbox]:checked + label:before {
    border-width: 12.5px;
    border-color: $c-green;
}



/*
 * Advanced Dropdown width search form inside - based on select/option html component
 */


.select2-container {
    z-index: 1050;

    .select2-results {
        position: static;

        &__options {
            position: static;
            //padding-bottom: 10px;
            //margin-bottom: 55px;

            li[id$="-new"] {
                box-sizing: border-box;
                position: absolute; //sticky;
                right: 7px;
                bottom: 7px; //-3px;
                left: 7px;

                background: $c-white;
                //transform: translateY(100%);

                &::before {
                    content:'';
                    position: absolute;
                    top: -5px;
                    left: 0;
                    right: 0;

                    height: 1px;

                    background: $c-gray-light;
                }

               a {
                   border: 1px solid $c-gray-lighter-alt !important;
                   border-radius: 3px;
               }

               /* &:hover {
                    background-color: $c-white !important;
                    color: $c-black !important;
                    border-radius: 0;
                }*/
                /*a {

                    display: block;

                    background-color: $c-dropdown !important;
                    color: $c-dropdown-color !important;
                    padding: 6px;
                    line-height: 24px;
                    border-radius: 3px;

                    &:hover {
                        background-color: $c-dropdown-hover !important;
                        color: $c-dropdown-hover-color !important;
                    }
                }*/

            }
        }

        &__option {

           /* &:empty{
                padding: 0;
                visibility: hidden;
            }*/
            &:hover,
            &[aria-selected=true] {
                background-color: $c-dropdown-hover !important;
                color: $c-dropdown-hover-color !important;
            }

            &--highlighted {
                background-color: $c-dropdown-hover !important;
                color: $c-dropdown-hover-color !important;
            }

        }
    }

}

.checkbox.checkbox-circle {
    input[type=checkbox] {
        display: none;
    }
}

.checkbox.checkbox-custom {
    label {
        width: auto;
        height: 25px;
        line-height: 25px;
        padding: 0 {
            left: 35px;
        }

        &::after {
            top: 1px;
        }

        span {
            display: inline-block;
            height: 25px;
            line-height: 29px;
        }
    }



    input[type=checkbox]:checked + label:before {
        border-width: 12.5px;
        border-color: $c-black;
    }
}

label.required:before {
    content: "* ";
    color: $c-pluriell-red;
}

.start {
    color: $c-pluriell-red;
}


.icon-tootltip {
    width:50%
}
