

.nav-tabs-simple > li > a:after {
    background-color: $c-tab-border;
}

.nav-tabs-simple.nav-tabs-left > li > a,
.nav-tabs-simple.nav-tabs-right > li > a
{
    padding: 10px;
}

.nav-tabs-simple.nav-tabs-left > li > a:hover,
.nav-tabs-simple.nav-tabs-right > li > a:hover
{
    background-color: rgba($c-state-hover, 0.05);
}

.nav-tabs-simple.nav-tabs-left > li > a:hover:after,
.nav-tabs-simple.nav-tabs-right > li > a:hover:after
{
    width: 3px;
    background-color: $c-tab-border-hover;
}

.nav-tabs-simple.nav-tabs-left > li > a.active:after,
.nav-tabs-simple.nav-tabs-right > li > a.active:after
{
    width: 3px;
    background-color: $c-tab-border-active;
}