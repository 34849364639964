.#{$namespace}icon {
    &-drag {
        display: inline-block;
        width: 20px;
        height: 20px;
        top: auto;
        background-image: url('/build/img/design/icon-drag.png');
        background-position: center;
        background-repeat: no-repeat;
    }
}