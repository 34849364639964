

.dropdown-menu {
    &.datepicker {
      z-index: 1050 !important;
    }

    .dropdown-item {
        font-size: 11px;
        overflow: hidden;
        color: $c-gray;
        opacity: 1;

        height: 35px;
        line-height: 35px;
        vertical-align: middle;

        font :{
            size: 13px;
        }

        i {
            display: inline-block;
            font-size: 13px;
            vertical-align: middle;
        }

        &:hover,
        &:focus,
        &:active
        {
            background-color: $c-dropdown-hover; //$c-beige;
            color: $c-white;
            border-radius: 0;
        }


    }

    & > li:first-child,
    & > .dropdown-item:first-child
    {
        padding-top: 0;
        overflow: hidden;
        border: 0 {
            top-left-radius: 3px;
            top-right-radius: 3px;
        }
    }

    & > li:last-child,
    & > .dropdown-item:last-child
    {
        margin-top: 0;
        padding-bottom: 0;

        border: 0 {
            bottom-left-radius: 3px;
            bottom-right-radius: 3px;
        }
    }

    .dropdown-menu-actions {
        margin-top: 5px;
        border-top: 1px solid $c-gray-light;
        padding: 5px 10px 0 10px;
        text-align: center;

        .btn {
            //display: block;
        }

        /*.btn {
            box-sizing: border-box;
            border-radius: 35px !important;
            margin:{
                top: 5px !important;
                right: 10px;
                left: 10px;
            }
            width: calc(100% - 20px);
        }*/
    }
}
