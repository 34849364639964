
.modal {

    .modal-dialog {
        &.modal-lg {

            width: auto;
            max-width: 800px;
        }
    }

    .modal-header {
        padding: {
            top: 20px;
            bottom: 20px;
        }
        border-bottom: 1px solid $c-gray-light;

        h5 {
            margin: 0;
            color: $c-black;
            font-size: 20px;
        }
    }

    .modal-content {
        max-height: 90vh;

        .modal-body {
            overflow-y: auto;

            padding: {
                top: 25px;
                bottom: 25px;
            }
        }
    }
}
