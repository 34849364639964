

.#{$namespace}label {
    &-not-read {
        display: inline-block;
        height: 20px;
        padding: 0 7px;
        box-sizing: border-box;

        border-radius: 10px;

        background-color: $c-not-read;
        color: $c-white;
        line-height: 22px;
        font: {
            size: 12px;
        }
    }

    &-private {
        position: relative;
        display: inline-block;
        width: 26px;
        height: 26px;

        box-sizing: border-box;

        border-radius: 50%;
        text-align: center;

        background-color: $c-private;
        color: $c-gray-dark;

        font: {
            size: 12px;
        }

        .fa {
            position: relative;
            top: 50%;
            display: inline-block;
            font-size: 16px;
            color: $c-gray-dark;

            transform: translateY(-50%);
        }
    }

}