

.#{$namespace}report {


    .h1 {
        display: block;
        margin: 0;
        padding: 0;

        font: {
            family: $base-font-family-bold ;
            size: 34px;
        }
        color: $c-black;

        @include max-screen($pages-bp-small) {
            font: {
                size: 26px;
            }
        }
    }

    .h2 {
        display: block;
        margin: 0;
        padding: {
            top: 5px;
            bottom: 45px;
        }

        font-size: 24px;

        @include max-screen($pages-bp-small) {
            font: {
                size: 18px;
            }
        }
    }


    &-info-row {
        &-1 {
            padding-bottom: 15px;
            color: $c-gray-light-alt;
            strong {
                color: $c-gray;
            }
        }

        &-2,
        &-3
        {
            margin: {
                top: 25px;
                bottom: 15px;
            }

            border: {
                top: 1px solid $c-gray;
                bottom: 1px solid $c-gray;
            }

            color: $c-gray-light-alt;

            strong {
                color: $c-gray;
            }

            & > [class*='col-'] {
                padding: 15px !important;

                & + [class*='col-'] {
                    border-left: 1px solid $c-gray;
                }
            }
        }
    }

    &-section {

        &-title {
            margin: {
                top: 25px;
                bottom: 15px;
            }
            background-color: $c-gray-light;
            padding: 5px 15px;

            font: {
                size: 18px;
                //weight: 700;
            }
        }

        &-images {
            margin-bottom: 25px;

            img {
                display: block;
                max-width: 100%;
                width: auto;
            }

        }

        &-content {
            padding: {
                right: 15px;
                left: 15px;
            }
            line-height: normal;


            ol, ul {
                margin: {
                    top: 15px;
                    bottom: 20px;
                }
            }
        }
    }

    &-add {
        /*#image-preview:empty {
            display: none;
            visibility: hidden;
        }*/
        #documentFileName:not(:empty){
            padding: {
                top: 15px;
                bottom: 25px;
            }
            font: {
                size: 16px;
            }
            i {
                font-size: 24px;
            }
        }

        &-step {
            padding: 0 25px;

            & > div {
                padding: 15px 0 30px 0;
                border-bottom: 1px solid $c-gray-light;

                &:nth-last-of-type(1) {
                    border-bottom: 0 none;
                }
            }

            h3 {
                padding-bottom: 15px;
            }

            &-number {
                position: relative;
                top: -3px;

                display: inline-block;
                width: 30px;
                height: 30px;
                margin: {
                    //top: -10px;
                    right: 5px;
                }

                background-color: $color-primary;
                border-radius: 50%;

                font: {
                    size: 18px;
                }
                text-align: center;
                line-height: 34px;
                color: $c-white;
            }
        }

        &-folder-add {
            padding-top: 10px;
        }


    }



}


// ----------------------
// Modal pour report
// ----------------------

.#{$namespace}modal-report-header {
    position: relative;

    h5 {
        height: 56px;
        @include max-screen($pages-bp-small) {
            font: {
                size: 16px !important;
            }
        }
    }
}

.#{$namespace}modal-report-actions {
    position: absolute;
    right: 25px;
    top: 50%;

    transform: translateY(-50%);

    button {
        display: inline-block;

        & + button {
            margin-left: 10px;
        }
    }

    .close {
        position: relative;
        right: auto;
        top: auto;
    }
}

// ----------------------
// Print version
// ----------------------

/*@media print {
    .#{$namespace}print-area{
        background-color: white;
        height: 100%;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0;
        padding: 15px;
        font-size: 14px;
        line-height: 18px;
    }
}*/

.#{$namespace}print-area {
    &-main-title {
        display: none;
        visibility: hidden;


    }
}

@media print {
    .#{$namespace}print-area {
        &-main-title {
            display: block;
            visibility: visible;


        }
    }
}
