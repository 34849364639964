

.horizontal-app-menu {


    .menu-bar {

        @include max-screen($pages-bp-large) {
            background-color: $c-black-light; //rgba($c-black, 0.87); //$c-gray-lighter;
            //padding-bottom: 100px;
        }

        .toggle-sidebar {
            position: absolute;
            right: 0;

            font-size: 25px;
            padding: 5px;
            margin: 0;
            color: $c-white;
        }

        & > ul {
            order: 2;
            //margin-top: 25px;

            @include max-screen($pages-bp-large){
                overflow-x: initial;
            }


            @include min-screen($pages-bp-large){
                //margin-top: 0;
                height: 40px;
                order: 1;
            }


            & > li {
                //margin-bottom: 5px;
                border-bottom: 2px solid transparent;


                @include max-screen($pages-bp-large){
                    border-bottom: 0;
                }


                &:first-child > a {
                    padding: 0 15px;

                    @include max-screen($pages-bp-large) {
                        padding: 7px 15px;
                    }
                }

                &:last-child > a {
                    padding: 0 15px;

                    @include max-screen($pages-bp-large) {
                        padding: 7px 15px;
                    }
                }

                & + li {
                    //margin-left: 15px;
                }

                & > a {
                    padding: 0 15px;
                    color: rgba($c-white, 0.5); //$c-gray-lighter-alt;

                    @include max-screen($pages-bp-large) {
                        display: flex;
                    }

                    &[href='#'] {
                        //color: rgba($c-white, 0.2) !important;
                        cursor: not-allowed;
                        opacity: 0.5;
                        @include max-screen($pages-bp-large) {
                            opacity: 0.25;
                        }
                    }


                    .#{$namespace}title {

                        @include max-screen($pages-bp-large) {
                            line-height: normal;
                            padding: {
                                top: 10px;
                                bottom: 10px;
                            }
                            color: $c-white;
                            opacity: 0.5;
                        }
                    }

                    .#{$namespace}icon-thumbnail {
                        display: none;
                        visibility: hidden;

                        @include max-screen($pages-bp-large){
                            display: flex;
                            height: auto;
                            width: auto;
                            visibility: visible;

                            //line-height: 40px;
                            text-align: center;
                            vertical-align: top;
                            position: relative;
                            left: 0;
                            float: right;
                            margin-right: 14px;
                            //color: #788195;
                            font-size: 16px;

                            img, svg {
                                //margin-top: 5px;
                                width: 26px;
                                height: 26px;
                                opacity: 0.5;

                            }

                            svg {
                                path {
                                    fill: $c-white;
                                }
                            }
                        }
                    }

                    @include max-screen($pages-bp-large){
                        padding: 14px 15px;
                        border-left: 4px solid transparent;
                        font-size: 16px;
                    }

                    &:hover {
                        @include max-screen($pages-bp-large) {
                            background: rgba($c-white, 0.2);
                        }
                    }



                }


                /*&::after {
                    content: '';
                    position: absolute;
                    top: 100%;
                    right:0;
                    bottom: 0;
                    left: 0;
                    z-index: 0;
                    pointer-events: none;

                    background-color: rgba($c-white, 0.15);
                    transition: top 0.2s;
                }*/

                &:hover {
                    border-bottom-color: $c-white; //rgba($c-white, 0.5);

                    a {
                        color: $c-white;


                    }

                    /*&::after {
                        top: 0;
                    }*/
                }

                &.active{
                    border-bottom-color: $c-state-active-contrast; //$c-state-active;


                    & > a {
                        @include max-screen($pages-bp-large){
                            background-color: $c-black; //$c-beige;
                            //color: $c-state-active-contrast; //$c-white;
                            border-left-color: $c-white;
                            color: $c-white;

                            .#{$namespace}icon-thumbnail {
                                img,
                                svg
                                {
                                    opacity: 1;
                                }
                            }

                            .#{$namespace}title {
                                opacity: 1;
                            }
                        }
                    }

                    & > * {
                        color: $c-white;

                    }

                }
            }

        }


        &.#{$namespace}contrast {
            & > ul {
                & > li {
                    @include min-screen($pages-bp-large) {
                        & > a {

                            color: $c-gray-lighter-alt;
                        }

                        &:hover {
                            border-bottom-color: $c-state-hover;

                            a {
                                color: $c-state-hover;
                            }

                        }

                        &.active{
                            border-bottom-color: $c-state-active;
                            color: $c-state-active;

                            & > * {
                                color: $c-state-active;
                            }
                        }

                    }


                }
            }
        }

        .#{$namespace}menu-bar-mobile-footer {
            position: relative; //absolute;
            order: 3;
            padding: {
                top: 25px; //60px;
                bottom: 15px;
            }


            .copyright {
                padding:  10px 0 0 0;
                border-top: 0; //1px solid rgba($c-white, 0.2);
                font-size: 10px;
                line-height: normal;

                .small {
                    line-height: normal;
                }


                .#{$namespace}copyright-part2 {
                    display: block;
                }

                .sm-block {
                    display: none;
                    visibility: hidden;

                   /* margin-top: 20px;
                    padding: 10px 0;
                    border-top: 1px solid rgba($c-white, 0.2); //rgba($c-white, 0.1);

                    text-align: left;

                    a {
                        display: block;
                    }

                    .muted {
                        display: none;
                        visibility: hidden;
                    }*/
                }


            }


            @include min-screen($pages-bp-large) {
                display: none;
                visibility: hidden;
            }
        }


    }


    &.is-admin .menu-bar {
        margin-bottom: 35px;

        @include min-screen($pages-bp-large) {
            .#{$namespace}icon-thumbnail {
                display: none;
                visibility: hidden;
            }
        }

        .#{$namespace}switch-project {

            .#{$namespace}project-menu {
                position: relative;
                top: auto;
                right: auto;
                bottom: auto;
                left: auto;

                display: block;
                width: 100%;
                flex-direction: initial;
                background: transparent;

                transform: initial;

                &::before {
                    content: none;
                }

                .pg-close {
                    display: none;
                    visibility: hidden;
                }

                & > ul {
                    display: block;
                    flex: none;
                    flex-flow: initial;
                    padding-top: 5px;

                    & > li {
                        display: inline-block;
                        width: calc(100% / 3);

                        float: left;
                        padding: 5px;

                        &:nth-of-type(3+3n) {
                            clear: left;
                        }

                        & > a {
                            display: block;
                            width: 100%;
                            height: 80px;

                            border: 1px solid rgba($c-white, 0.25);
                            border-radius: 3px;
                            padding: 5px !important;

                            text-align: center;
                            color: $c-white !important;
                            font-size: 11px;
                            line-height: 32px;


                            svg, img {
                                height: 28px;
                                opacity: 0.85;
                            }

                            .#{$namespace}icon-thumbnail {
                                //reset
                                float: none;
                                margin-right: 0;

                                //styling
                                text-align: center;
                                display: block;
                                padding-top: 7px;
                            }

                            .#{$namespace}title {
                                text-align: center;
                                display: block;
                                opacity: 0.85;
                            }
                        }

                        &.active {
                            a {
                                border-color: rgba($c-white, 1);
                                background-color: rgba($c-white, 0.25);
                            }
                        }
                    }
                }
            }
        }
    }

}


.main-frame {
    height: 100%;
}

.page-container {
    position: relative;
    padding-top: 0;

    /*@include max-screen($uk-bp-small) {

    }

    @include min-screen($uk-bp-medium) {
        padding-top: 25px;
    }

    @include min-screen($pages-bp-large) {
        padding-top: 45px;
    }*/

    .page-content-wrapper {
        //min-height: 100vh;
        display: flex;
        flex-direction: column;


        .content {
            flex: 1 1;
            //min-height: calc(100vh - 195px) !important;

            @include max-screen($pages-bp-medium) {
                padding-top: 25px;
                padding-bottom: 130px;
            }

            @include min-screen($pages-bp-large) {
                padding-top: 45px;
            }
        }

        .footer {
            margin-top: auto;
            //padding-bottom: 100px;
            //position: relative;
            //bottom: 0;
            padding-top: 25px;
        }
    }

    .#{$namespace}page-title-bar {
        margin-bottom: 15px;
        border-bottom: 1px solid $c-gray-lighter;
        min-height: 42px;

        @include max-screen($uk-bp-medium) {
            //background-color: $c-white;

            &::before {
                content: '';
                background: $c-white;

                position: absolute;
                top: 0;
                right: -100%;
                bottom: 0;
                left: -100%;

            }
        }

        @include max-screen($pages-bp-large) {
            padding: 15px !important;
        }

        @include max-screen($pages-bp-small) {
            padding: 10px 0 !important;
        }

        .#{$namespace}page-title {
            position: relative;
            display: inline-block;
            overflow: visible;

            margin: 0;
            min-height: 50px;
            padding: 0;

            font: {
                family: $base-font-family;
                size: 18px;
                weight: 600;
            }
            line-height: normal;
            text-transform: none;
            letter-spacing: 0.06em;
            color: $c-black;


            .btn-back {
                box-sizing: border-box;
                position: relative;
                top: 0; //50%;
                vertical-align: middle;

                //transform: translateY(-50%);

                @include max-screen($pages-bp-large) {
                    display: none;
                    visibility: hidden;
                }
            }

            h1 {
                font: {
                    family: $base-font-family-bold;
                }
                letter-spacing: -2px;
            }


            h1, h2, h3, h4, h5, h6
            {
                margin-top: 0; //-3px;
                margin-bottom: 0;
                padding: 0;
                display: inline-block;
                vertical-align: middle;
                min-height: 50px;
                line-height: 42px;

                body.mac & {
                    line-height: 50px;
                }

                small {
                    display: inline-block;
                    margin-left: 15px;
                    color: $c-gray-lighter-alt; //rgba($c-black, 0.5);
                }

                @include max-screen($uk-bp-small) {
                    font-size: 24px;
                }
            }
        }

        .card-header-actions {
            .btn:not(.has-no-icon) {
                @include max-screen($uk-bp-small) {
                    padding: {
                        left: 0;
                        right: 0;
                    }
                    text-align: center;
                    width: 42px;
                }

                span {
                    @include max-screen($uk-bp-small) {
                        display: none;
                        visibility: hidden;
                    }
                }
            }
        }
    }


}


// Contrast mode
// -------------
body.#{$namespace}env-light {

    &.horizontal-app-menu .header .header-icon, .header .header-icon,
    &.horizontal-app-menu .menu-bar > ul > li:hover a,
    &.horizontal-app-menu .menu-bar > ul > li.active > *,
    &.horizontal-app-menu .header .toggle-sidebar,
    .header .toggle-sidebar
    {
        color: $c-black;
    }

    &.horizontal-app-menu .menu-bar > ul > li > a {
        color: $c-gray-darker;
    }

    &.horizontal-app-menu.fixed-header .header {
        border-bottom: 1px solid $c-gray-lighter-alt-secondary;
    }

    &.horizontal-app-menu .menu-bar > ul > li.active{
        border-bottom-color: $c-black;
    }

}





