

.#{$namespace}switcher {
    margin: 0;
    padding: 0;
    list-style: none;

    display: inline-block;
    width: auto;
    font-size : 0;

    & > li {
        display: inline-block;
        padding: 0;
        margin: 0;

        a {
            display: block;

            margin: 0;
            border-radius: 0;
            border: 1px solid $c-btn-default-active-border;
            width: 100%;
            height: 42px;

            padding: {
                right: 15px;
                left: 15px;
            }
            text-transform: none;
            font-size: 16px;
            line-height: 42px;

            svg {
                position: relative;
                //top: -2px;
                height: 20px;
            }

            &:hover {
                border-radius: 0;
                border-width: 1px;
            }
        }

        & + li {
            a {
                border-left: 0;

                &:hover {
                    border-left: 0;
                }
            }
        }

        &.uk-active {
            a {
                background-color: $c-btn-default-active-background;
                color: $c-btn-default-active-color;
            }

        }

        &:nth-of-type(1) {
            a {
                border: {
                    top-left-radius: 5px !important;
                    bottom-left-radius: 5px !important;
                }
            }

        }

        &:nth-last-of-type(1) {
            a {
                border: {
                    top-right-radius: 5px !important;
                    bottom-right-radius: 5px !important;
                }
            }

        }
    }
}

.#{$namespace}switcher-toolbar {
    text-align: right;

    &-wrapper {
        display: inline-block;
        width: auto;
        font-size : 0;

        button:nth-of-type(1) {
            border: {
                top-left-radius: 5px !important;
                bottom-left-radius: 5px !important;
            }
        }

        button:nth-last-of-type(1) {
            border: {
                top-right-radius: 5px !important;
                bottom-right-radius: 5px !important;
            }
        }

        button {
            margin: 0;
            border-radius: 0 !important;
            border-width: 1px;
            height: 42px;

            padding: {
                right: 15px;
                left: 15px;
            }
            text-transform: none;
            line-height: 42px;

            svg {
                position: relative;
                //top: -2px;
                height: 20px;
            }

            &:hover {
                border-radius: 0;
                border-width: 1px;
            }

            & + button {
                border-left: 0 !important;

                &:hover {
                    border-left: 0;
                }
            }

            &.uk-active,
            &.active
            {
                background-color: $c-btn-default-active-background;
                color: $c-btn-default-active-color;
            }

            &[disabled] {
                color: rgba($c-btn-default-color, 0.5);

                &:hover {
                    cursor: not-allowed;
                    background-color: inherit;
                    color: inherit;
                }
            }
        }
    }
}