

.#{$namespace}timeline {

    width: 100%;


    &-actions {
        text-align: center;

        .pg-social {
            position: relative;
            top: 3px;
            margin-right: 5px;
        }
    }

    &-comment_add {
        position: relative;
        width: 100%;
        max-width: 700px;

        margin: auto;

        text-align: left;

        .#{$namespace}actions {
            background-color: $c-white;
            padding: 10px 15px;
            text-align: right;

            border: {
                right: 1px solid rgb(196, 196, 196);
                bottom: 1px solid rgb(196, 196, 196);
                left: 1px solid rgb(196, 196, 196);
            }

            .pg-plus_circle {
                position: relative;
                top: 2px;
                margin-right: 5px;
            }

            & > * {
                margin-left: 15px;
            }
        }

        &.is-show {
            display: block;
        }
    }

    &-user-name {
        small {
            color: #9c9c9c;
            font-size: 11px;
        }
    }
}

.timeline {

    &-container {
        width: 95%;

        @include min-screen($uk-bp-medium) {
            width: 100%;
        }

        &:not(.left) .timeline .timeline-block {
            margin: 2em 0;
        }
    }

    &-block {

        .timeline-point i {
            margin: 0;
            transform: translate(-50%, -50%);
        }



        .social-card.share {
            .card-header {
                border-top: 3px solid $c-gray-light;
                margin-bottom: 0;
                border-bottom: 1px solid $c-gray-lighter;

                &:hover {
                    background-color: transparent;
                    cursor: default;
                    border-bottom-color: $c-gray-lighter;
                }

                .user-pic > img {
                    width: 40px;
                    height: 40px;
                }

                .#{$namespace}type {
                    display: inline-block;
                    padding: 4px 10px;
                    opacity: 1;

                    border-radius: 3px;
                    font: {
                        size: 11px;
                        weight: bold;
                    }
                    background-color: $c-gray;
                    color: $c-white;

                }
            }

            .card-content {
                &.has-buttons {
                    &::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;

                        display: block;
                        width: 100%;
                        height: 75px;
                        opacity: 0;

                        background: -moz-linear-gradient(top, rgba($c-black,0.5) 0%, rgba($c-black,0) 100%);
                        background: -webkit-linear-gradient(top, rgba($c-black,0.5) 0%,rgba($c-black,0) 100%);
                        background: linear-gradient(to bottom, rgba($c-black,0.5) 0%,rgba($c-black,0) 100%);

                        transition: opacity 0.15s;
                    }
                    &:hover {
                        &::before {
                            opacity: 1;
                            transition: opacity 0.25s;
                        }
                    }
                }

                img {
                    width: 100%;
                }

                [class*='is-file-type-'],
                [class*='is-type-']
                {
                    display: block;
                    padding: 10px 25px;

                    background-color: $c-gray;

                    text-align: center;
                    font-size: 80px;
                    color: $c-white;

                    i {
                        vertical-align: middle;
                    }
                }

                .is-file-type-report {
                    background-color: $c-type-report;
                }

                .is-file-type-pdf {
                    background-color: $c-type-pdf;
                }
                .is-file-type-word {
                    background-color: $c-type-word;
                }
                .is-file-type-excel {
                    background-color: $c-type-excel;
                }
                .is-type-task {
                    background-color: $c-type-task;
                }
            }

            .card-footer {
                border-top: 1px solid $c-gray-lighter;
                color: $c-gray-light-alt;

                &:hover {
                    background-color: transparent;
                    cursor: default;
                    border-top-color: $c-gray-lighter;
                }
            }
        }

        &.#{$namespace}type-note {

            .social-card.share {
                .card-header {
                    border-top-color: $c-type-note;

                    .#{$namespace}type {
                        background-color: $c-type-note;
                    }
                }
            }

            .timeline-point {
                background-color: $c-type-note;
            }

        }

        &.#{$namespace}type-comment {

            .social-card.share {
                .card-header {
                    border-top-color: $c-type-comment;

                    .#{$namespace}type {
                        background-color: $c-type-comment;
                    }
                }
            }

            .timeline-point {
                background-color: $c-type-comment;
            }

        }

        &.#{$namespace}type-report {

            .social-card.share {
                .card-header {
                    border-top-color: $c-type-report;

                    .#{$namespace}type {
                        background-color: $c-type-report;
                    }
                }
            }
            .timeline-point {
                background-color: $c-type-report;
            }

        }

        &.#{$namespace}type-file {

            .social-card.share {
                .card-header {
                    border-top-color: $c-type-file;

                    .#{$namespace}type {
                        background-color: $c-type-file;
                    }
                }
            }
            .timeline-point {
                background-color: $c-type-file;
            }

        }

        &.#{$namespace}type-task {

            .social-card.share {
                .card-header {
                    border-top-color: $c-type-task;

                    .#{$namespace}type {
                        background-color: $c-type-task;
                    }
                }
            }
            .timeline-point {
                background-color: $c-type-task;
            }

        }

    }

}
