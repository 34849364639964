%ptro-btn-common {
    position: relative;
    box-sizing: border-box;

    font-family: $f-body;
    font: {
        size: 15px;
        weight: normal;
    }
    padding: 0 20px;
    letter-spacing: 0.01em;
    border: 2px solid $c-btn-default-border {
        radius: 21px;
    }
    height: 42px;
    min-width: 42px;
    line-height: 40px;


    color: $c-btn-default-color;
    background-color:  transparent;

    @include max-screen($uk-bp-small) {
        padding: 0 15px;
        font-size: 13px;
    }

    &:hover{
        background-color: $c-btn-default-background-hover !important;
        color: $c-btn-default-color-hover !important;
        border: 2px solid $c-btn-default-border !important;
        border-radius: 21px;
    }
}


#painterro-container-wrapper {
    &.ptro-wrapper {
        bottom: 64px;
        background-color: $c-white;

        .ptro-common-widget-wrapper .ptro-settings-widget {
            width: 450px;
            border-radius: 5px;
            padding: 35px 25px;
            background-color: $c-white;

        }

    }

    .ptro-color-main {
        background-color: $c-white !important;
    }

    .ptro-color,
    .ptro-bordered-btn
    {
        border-color: $color-primary;
    }

    .ptro-color-btn.ptro-bordered-btn {
        border-radius: 50% !important;
        overflow: hidden;
    }

    span.ptro-btn-color-checkers {
        border-radius: 50% !important;
        overflow: hidden;
        opacity: 0.5;
    }

    .ptro-color-alpha-label,
    .ptro-label
    {
        float: none !important;
        //padding: 5px 5px 5px 10px;
        //min-height: 32px;
        vertical-align: middle;
        text-align: left !important;
    }

    input.ptro-input,
    input.ptro-input:focus,
    select.ptro-input,
    select.ptro-input:focus
    {
        border: 1px solid $c-black;
        height: 32px;
        line-height: 32px;
        color: $c-black;
    }


    // Modal Color Picker
    .ptro-color-main.ptro-pallet {

        //width: 230px;
        border-radius: 5px;
        padding: {
            top: 20px;
            bottom: 20px;
        }

        .ptro-icon-btn.ptro-pipette.ptro-color-control {
            @extend %ptro-btn-common;

            min-width: 32px;
            width: 32px;
            height: 32px;

            padding: 0;
            text-align: center;
            line-height: 32px;

            &.ptro-pipette i {
                line-height: 29px;
            }
        }

        .ptro-named-btn.ptro-close-color-picker.ptro-color-control {
            @extend %ptro-btn-common;

            margin-right: 2px;
            padding: 0 5px;
            height: 32px;
            min-width: 32px;

            font-size: 14px;
            line-height: 32px;

          /*  border-color: $color-danger;
            background: transparent;
            color: $color-danger;*/

            &:hover {
                /*border-color: $color-danger !important;
                background: $color-danger !important;
                color: $c-white !important;*/
            }
        }

    }



}

.ptro-crp-el {
    box-shadow: 5px 5px 15px rgba($c-black, 0.15);
}



.ptro-named-btn.ptro-color-control  {

    &.ptro-apply {
        @extend %ptro-btn-common;
        @include button($c-white, $color-primary, $color-primary);
    }

    &.ptro-close {
        @extend %ptro-btn-common;

        &:hover {
            @include button($color-primary, transparent, $color-primary);
        }
    }

    &.ptro-clear {
        @extend %ptro-btn-common;

        padding: 0 10px;
        height: 32px;
        min-width: 32px;

        font-size: 14px;
        line-height: 32px;

        border-color: $color-danger;
        background: transparent;
        color: $color-danger;

        &:hover {
            border-color: $color-danger !important;
            background: $color-danger !important;
            color: $c-white !important;
        }
    }
}




#painterro-container-bar {

    &.ptro-color-main.ptro-bar {
        box-sizing: border-box;
        height: 64px;
        padding: {
            top: 10px;
            bottom: 10px;
        }

        border-top: 1px solid $c-gray-light;
        background-color: $c-white;

        & > div {
            overflow-x: auto;
            overflow-y: hidden;
            height: 64px;
            box-sizing: border-box;

            padding: {
                right: 15px;
                left: 15px;
            }
        }

        /*.ptro-bar-right {
            margin-right: 15px;
        }*/

        .tool-controls {
            display: inline-block;
            margin-left: 15px;
            background-color: $c-gray-lighter;
            padding: 2px 10px;
            border: 1px solid $c-gray-light {
                radius: 5px;
            }

            &:empty {
                display: none;
                visibility: hidden;
            }
        }

        .ptro-tool-ctl-name {
            margin-left: 10px;
            background-color: transparent;

            &:nth-of-type(1) {
                margin-left: 0;
            }
        }

        .color-diwget-btn.ptro-color-btn.ptro-bordered-btn {
            border: 1px solid $c-black {
                radius: 50%;
            }
            overflow: hidden;
        }

        .ptro-btn-color-checkers-bar {
            border-radius: 50% !important;
            overflow: hidden;
            opacity: 0.5;
        }


        input.ptro-input,
        input.ptro-input:focus,
        select.ptro-input,
        select.ptro-input:focus
        {
            border: 1px solid $c-black;
            height: 32px;
            line-height: 32px;
            color: $c-black;
        }
    }

    .ptro-color-control,
    .ptro-color-active-control
    {
        background-color: transparent;
    }

    .ptro-color-control {
        &.ptro-icon-btn {
            margin-top: 0;
            border: 1px solid transparent;
            border-radius: 50%;
            width: 42px;
            height: 42px;
            padding: 0;

            line-height: 42px;
            text-align: center;

            .ptro-icon {
                line-height: 42px;
            }

            &:not([disabled]) {

                &:hover {
                    background-color: $c-btn-default-active-background;
                    color: $c-white;
                    border-color: $c-btn-default-active-border;
                }

            }
        }

        &.ptro-color-active-control {
            &.ptro-icon-btn {
                background-color: $c-btn-default-active-background;
                color: $c-white;
                border-color: $c-btn-default-active-border;
            }
        }
    }



    .ptro-icon-btn:disabled {
        color: $c-link-disabled;
        cursor: not-allowed;
    }


}



