
body.page-time-tracker {


    #prestations-period-dates-range {
        text-transform: capitalize;
    }

    #prestations-datatables-container {

        h1, h2, h3, h4, h5, h6 {
            margin-top: 50px;
            bottom: 0;

            small {
                display: inline-block;
                margin-left: 15px;
                color: $c-gray-lighter-alt; //rgba($c-black, 0.5);
            }
        }

        .table {
            thead {
                th {
                    box-sizing: border-box !important;
                    //width: 100%;
                    //min-width: 300px;

                    /*@include min-screen($uk-bp-xlarge) {
                        width: 100%;
                        min-width: 300px;
                    }*/

                    &.project {
                        width: 25%;
                    }

                    &.billable,
                    &.time,
                    &.date {
                        //min-width: 55px;
                        //width: 55px !important;
                        text-align: center;

                        @include min-screen($uk-bp-xlarge) {
                            min-width: 55px;
                            width: 55px !important;
                        }
                    }

                    &.comment {
                        //min-width: 350px;
                        //width: 350px !important;

                        /*@include min-screen($uk-bp-xlarge) {
                            min-width: 350px;
                            width: 350px !important;
                        }*/
                    }

                    &.buttons {
                        //min-width: 55px;
                        //width: 55px !important;

                        @include min-screen($uk-bp-xlarge) {
                            min-width: 55px;
                            width: 55px !important;
                        }
                    }
                }
            }

            tbody {
                td {
                    padding: 10px;

                    &.time:not(.duration) {
                        color: $c-gray-lighter-alt !important;
                    }

                    &.duration {
                        color: $c-gray !important;
                    }

                    .rp-button-more {
                        height: auto;
                        padding: 0;
                        margin: 0;
                        border: none;
                        line-height: initial;

                        i {
                            vertical-align: middle;
                        }
                    }
                }
            }
        }
    }
}
