
body.page-login {
    min-height: 100%;

    .pg-colors {
        display: none;
    }

    .login-wrapper {
        background-color: $c-white;

        .bg-caption {
            width: 100%;
            max-width: 600px;

            @include max-screen($bt-bp-small) {
                position: absolute !important;
                bottom: 0;
                padding: 0 15px !important;

                text-align: center;
            }

            h2 {
                @include max-screen($bt-bp-small) {
                    display: none;
                    visibility: hidden;
                }
            }

        }


    }

    .bg-pic {
        background: {
            repeat: no-repeat;
            size: cover;
        }



        @include max-screen($bt-bp-small) {
            /*position: relative;
            order: 2;*/
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }

        h2 {
            font-size: 90px;
            line-height: 1.1em;

            @include max-screen($bt-bp-xxlarge) {
                font-size: 65px;
            }

            @include max-screen($bt-bp-xlarge) {
                font-size: 40px;
            }

            @include max-screen($bt-bp-large) {
                font-size: 36px;
            }

            @include max-screen($bt-bp-medium) {
                font-size: 28px;
            }
        }

        .bg-caption {
            text-shadow: 1px 1px 2px rgba($c-gray-dark, 0.7);
            max-width: 40%;

            @include max-screen($bt-bp-xxlarge) {
                max-width: 35%;
            }

            @include max-screen($bt-bp-xlarge) {
                max-width: 30%;
            }

            @include max-screen($bt-bp-large) {
                max-width: 35%;
            }

            @include max-screen($bt-bp-medium) {
                max-width: 40%;
                position: absolute !important;
            }

            @include max-screen($bt-bp-small) {
                max-width: 100%;
                //position: relative !important;
            }
        }

        .hint-text {
            text-shadow: 1px 1px 1px rgba($c-gray-dark, 0.7);
        }

    }

    .login-container {
        padding: 70px 70px 20px 70px;
        background-color: rgba($c-white, 0.9);
        font-size: 13px;

        @include max-screen($bt-bp-xlarge) {
            width: 40%;
        }

        @include max-screen($bt-bp-large) {
            width: 50%;
            padding: 50px 50px 20px 50px;
        }

        @include max-screen($bt-bp-small) {
            //order: 1;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            box-sizing: border-box;
            float: none;
            //margin: 15px;
            padding: 20px;
            width: 100%; //calc(100% - 30px);
            height: 100%; //calc(100% - 30px);
            //border: 1px solid rgba($c-white, 0.65);
            background-color:  rgba($c-black, 0.4); //rgba($c-white, 0.45);

            color: $c-gray-light-alt;
        }

        @media only screen and (max-width: $bt-bp-small - 1) and (orientation: landscape) {
            position: relative;
            top: initial;
            right: initial;
            bottom: initial;
            left: initial;

            height: auto;
        }
    }




    .#{$namespace}app-logo {
        padding-bottom: 25px;
        text-align: left;

        @include max-screen($bt-bp-small) {
            text-align: center;
            padding-bottom: 15px;
        }

        .#{$namespace}logo {
            @include max-screen($bt-bp-small) {
                display: none;
                visibility: hidden;
            }
        }

        .#{$namespace}logo-mobile {
            display: none;
            visibility: hidden;

            @include max-screen($bt-bp-small) {
                margin-top: 10px;
                display: inline-block;
                visibility: visible;
            }
        }



        img {
            display: inline-block;
            //float: left;

            height: 35px;
            width: auto;

        }

        h1, .h1 {
            box-sizing: border-box;
            display: inline-block;
            margin: 0 {
                left: 20px;
            }
            height: 35px;
            line-height: 42px;

            border-left: 2px solid $c-black;
            padding-left: 20px;

            font: {
                size: 26px;
            }
            vertical-align: middle;
            color: $c-black;
        }
    }

    a.text-info {
        color: $c-link !important;


        &:hover {
            color: $c-link-hover !important;
            //text-decoration: underline;
        }

        &:active {
            color: $c-link-active !important;
        }

        @include max-screen($bt-bp-small) {
            color: $c-link-contrast !important;
            display: block;
            text-align: center;

            &:hover {
                color: $c-link-hover-contrast !important;
            }

            &:active {
                color: $c-link-active-contrast !important;
            }
        }

    }

    .#{$namespace}login-intro {
        @include max-screen($bt-bp-small) {
            display: none;
            visibility: hidden;
        }
    }


    #form-login {
        position: relative;

        @include max-screen($bt-bp-small) {
            position: absolute;
            top: 50%;
            left: 20px;
            right: 20px;
            margin: 0;

            padding: 30px 20px 10px 20px !important;
            background-color: rgba($c-white, 0.9);
            border-radius: 10px;

            transform: translateY(-50%);
        }

        @media only screen and (max-width: $bt-bp-small - 1) and (orientation: landscape) {
            position: relative;
            top: auto;
            left: 0;
            right: 0;

            margin-top: 20px;
            margin-bottom: 60px;

            transform: translateY(0);
        }

        .#{$namespace}forgotten-password {
            text-align: right;

            @include max-screen($bt-bp-small) {
                text-align: center;
            }
        }
    }

    .checkbox {
        label {
            width: auto;
            height: 25px;
            line-height: 25px;
            padding: 0 {
                left: 35px;
            }

            &::after {
                top: 1px;
            }

            span {
                display: inline-block;
                height: 25px;
                line-height: 29px;
            }
        }

        input[type=checkbox]:checked + label:before {
            border-width: 12.5px;
            border-color: $c-checkbox-bg-checked;
        }

    }

    @include max-screen($bt-bp-small) {
        .#{$namespace}login-form-footer {
            display: flex;
            flex-direction: column;

            & > div {
                flex: 1;
            }

            .#{$namespace}remember-me {
                -webkit-order: 2;
                order: 2;

                border-top: 1px solid rgba($c-black, 0.5);
                padding-top: 5px;
            }

            .#{$namespace}action-submit {
                -webkit-order: 1;
                order: 1;

                text-align: center;
                padding: {
                    top: 5px;
                    bottom: 20px;
                }
            }
        }
    }

    .#{$namespace}copyright {
        .#{$namespace}copyright-part2 {
            @include max-screen($bt-bp-small) {
                display: block;
            }
        }
    }


}
