

.widget-intro-cart {

    padding: 25px; //40px;


    @include max-screen($pages-bp-large) {
        padding: 0;
    }

    h2 {
        margin: {
            top: 5px !important;
            bottom: 0 !important;
        }
        font: {
            size: 38px;
            weight: bold;
        }
        line-height: 44px;
        color: $c-black;

        @include max-screen($pages-bp-xlarge) {
            font-size: 28px;
            line-height: 34px;
            letter-spacing: 1px;
        }

        @include max-screen($pages-bp-large) {
            font-size: 24px;
            line-height: 28px;
            letter-spacing: 0.5px;
        }
    }

    h3 {
        margin: {
            top: 0 !important;
            bottom: 0 !important;
        }
        font: {
            size: 20px;
            weight: normal;
        }
        line-height: 44px;
        color: $c-black;

        @include max-screen($pages-bp-large) {
            font: {
                size: 16px;
            }
        }
    }

    .actions {
        padding-top: 30px;

        strong {
            display: block;
            margin-bottom: 7px;
        }
    }

    .simple-stat {
        //display: block;
        height: 100%;
        border-left: 1px solid $c-gray-light;
        padding: 10px 20px;


        text-align: center;
        color: $c-beige;
        font: {
            family: $base-font-family-bold;
            size: 78px;
            //weight: bold;
        }
        line-height: normal;

        @include max-screen($pages-bp-medium) {
            border-left: 0;
            padding: 5px 0;
        }

        a {
            display: inline-block;
            margin: auto;
        }

        small {
            display: block;
            color: $c-black;
            font: {
                family: $base-font-family-light;
                size: 13px;
            }
        }
    }
}

.widget-latest-records {

    .record-year {
        padding: 0 5px 0 0;

        font: {
            size: 18px; //22px;
            //weight: bold;
        }
        color: $c-gray-light-alt;

        border-bottom: 1px solid $c-gray-light-alt;
        text-align: left;

    }

    table {
        tr {
            td {
                padding: {
                    top: 5px;
                    bottom: 5px;
                }
                border-bottom: 1px solid $c-gray-light;
                vertical-align: top;
                line-height: normal;

                small {
                    display: block;
                    color: $c-gray-light-alt;
                }

                a:not(.btn) {
                    color: $c-gray;
                    &:hover {
                        color: $c-gray-light-alt;
                    }
                }

                &.record-title {
                    width: 50px;
                    padding-right: 10px;

                    font-weight: bold;
                    color: $c-gray;


                }

                &:last-of-type {
                    vertical-align: middle;
                }
            }

            &:last-of-type {
                td {
                    border-bottom: 0;
                }
            }
        }

        & + .record-year {
            margin-top: 25px;
        }
    }
}



.widget-project-progress {

    &__item {
        position: relative;
        padding: 0;

        border: 1px solid $c-gray-lighter{
            bottom: 0;
        }

        & + & {
            margin-top: 15px;
        }

        &-container {
            padding: 10px 10px 0 10px;
        }

        h5 {
            margin: 0 {
                bottom: 10px;
            }
            //font-weight: bold;
            a {
                color: $c-gray;

                &:hover {
                    color: $c-gray-lighter-alt;
                }
            }
        }

        .btn {
            position: absolute;
            top: 50%;
            right: 15px;

            transform: translateY(-50%);
        }

        .project-percent {
            display: inline-block;
            min-width: 45px;
            margin: {
                right: 25px;
                left: 5px;
            }

            font: {
                size: 16px;
                weight: bold;
            }
        }


        .pg-calender {
            font-size: 20px;
        }

        .progress.progress-small {
            margin-bottom: 0;
        }
    }


}

.widget-rss-reader {

    .card-header {
        margin-bottom: 0 !important;
    }

    .card-block {
        padding: 0;
        margin: 0;
        min-height: 220px;
    }

    .rss-feed {
        &-title {
            font: {
                size: 20px;
                weight: bold;
            }
            line-height: normal;
            color: $c-white;
        }

        &-date {
            margin: {
                top: 10px;
                bottom: 10px;
            }

            color: $c-gray-light-alt;
            line-height: normal;
        }

        &-description {
            img {
                display: none;
            }

        }

    }

    .rss-reader-carousel{
        position: relative;
        height: 100%;

        &:hover {
            .owl-controls {
                .owl-prev,
                .owl-next
                {
                    opacity: 1;
                    color: $c-link;
                }
            }

        }

        &--item {
            height: 100%;
            background: {
                repeat: no-repeat;
                position: center;
                size: cover;
            }

            &-overlay {
                position: absolute;
                top: auto;
                width: 100%;
                right: 0;
                bottom: 0;
                left: 0;

                height: auto;
                padding: 120px 20px 10px 20px;

                text-align: left;
                color: $c-white;


                background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.9) 100%);
                background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.9) 100%);
                background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.9) 100%);

            }

        }
        .owl-stage-outer {
            height: 100%;

            .owl-stage {
                height: 100% !important;

                .owl-item {
                    height: 100%;
                }
            }
        }


        .owl-controls {
            position: absolute;
            top: 50%;
            right: 0;
            left: 0;
            z-index: 2;

            transform: translateY(-50%);

            .owl-prev {
                margin-left: 15px;
                float: left;
                color: $c-white;
                font-size: 28px;

                opacity: 0.25;
                transition: opacity 0.2s;
            }

            .owl-next {
                margin-right: 15px;
                float: right;
                color: $c-white;
                font-size: 28px;

                opacity: 0.4;
            }
        }
    }

    ul.news-list {
        padding-left: 0;
        list-style: none;

        li {
            list-style: none;
            padding: {
                top: 15px;
                bottom: 15px;
            }
            border-bottom: 1px solid $c-gray-light;

            &:last-of-type {
                border-bottom: 0;
            }

            p {
                font: {
                    size: 12px;
                }
                line-height: 1.5em;
            }

        }
    }
}