.card {


    .card-header {
        margin-bottom: 15px;
        border-bottom: 1px solid $c-gray-lighter;
        min-height: 42px;

        @include max-screen($pages-bp-large) {
            padding: 15px;
        }

        @include max-screen($pages-bp-small) {
            padding: 10px;
        }

        &.no-padding {
            @include max-screen($pages-bp-large) {
                padding: {
                    right: 15px !important;
                    left: 15px !important;
                }
            }
        }

        &-actions {
            @include max-screen($pages-bp-medium) {
                width: 100%;
            }
        }


        .card-title {

            // ----------------- NOTE #1
            // Note (by Bastien): Not used yet! Changement ".cart-title" par ".#{$namespace}page-title" (voir _layout.scss)

            /*position: relative;
            overflow: visible;
            min-height: 50px;

            font: {
                family: $base-font-family;
                size: 18px;
                weight: 600;
            }
            text-transform: none;
            color: $c-black;


            .btn-back {
                box-sizing: border-box;
                position: relative;
                top: 0; //50%;
                vertical-align: middle;

                //transform: translateY(-50%);
            }


            h1, h2, h3, h4, h5, h6
            {
                margin-top: 0; //-3px;
                margin-bottom: 0;
                padding: 0;
                display: inline-block;
                vertical-align: middle;
                min-height: 50px;
                line-height: 42px;

                body.mac & {
                    line-height: 50px;
                }

                small {
                    display: inline-block;
                    margin-left: 15px;
                    color: $c-gray-lighter-alt; //rgba($c-black, 0.5);
                }
            }*/
            // ----------------- EOF NOTE #1

            &-metas {
                display: block;
                font: {
                    size: 20px;
                }
                letter-spacing: normal;
                line-height: normal;
                color: $c-gray-lighter-alt;
            }

            .btn {
                vertical-align: top;
                width: auto;
                overflow: hidden;


                &.btn-back {
                    width: auto;
                    padding: {
                        right: 13px;
                        left: 13px;
                    }

                    transition: all 0.45s;

                    span {
                        display: inline-block;
                        margin: 0;
                        opacity: 0;

                        width: 0;

                        transition: all 0.15s;
                    }

                    &:hover {
                        span {
                            opacity: 1;
                            width: auto;
                            padding-left: 5px;

                            transition: all 0.45s;
                        }
                    }
                }
            }


        }

    }

    .card-block {
        padding: 20px;

        @include max-screen($pages-bp-large) {
            padding: 15px;
        }

        @include max-screen($pages-bp-small) {
            padding: 10px;
        }
    }

}