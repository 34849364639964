/* ==========================================================================
   @mixins
   ========================================================================== */


/*
 * Documentation about mixin with SASS:  http://sass-lang.com/documentation/file.SASS_REFERENCE.html#mixins
 *
 */


// ========================================================================== 1. Mixins Global


/* -------------------------------------------------------------
 * Pour gérer les background CSS avec image Rétina/HD
 ---------------------------------------------------------------*/
// @example: @include background-image-retina('foobar', 'png', 10px, 20px);
@mixin background-image-retina($file, $type: 'png', $width: 'auto', $height: 'auto', $suffix: '@2x' ) {
    background-image: url($file + '.' + $type);

    -webkit-background-size: $width $height;
    -moz-background-size: $width $height;
    background-size: $width $height;

    @media (-webkit-min-device-pixel-ratio: 2), (-moz-min-device-pixel-ratio: 2) {
        & {
            background-image: url($file + $suffix '.' + $type);
        }
    }
}


/* -------------------------------------------------------------
 * Font-Face code generator
 ---------------------------------------------------------------*/
// Compass have a "font-face" mixin but we write a new mixin because the fonts management is specific on this project
// GET MIXIN: @include font-face-load(([$name], [$woff],, [$truetype], [$svg], , [$eot], [$weight], [$style]);

/*
 * -------------------------------------------
 * Example the code that we use:
 * -------------------------------------------

   @include font-face-load('MyFont',
       'my-font.woff',
       'my-font.ttf',
       'my-font.svg',
       'my-font.eot',
      normal, normal
   );

 ---------------------------------------------
 */
@mixin font-face-load($name, $woff: false, $truetype: false, $svg: false, $eot: false, $weight: false, $style: false) {

    $iefont: unquote("#{$eot}?#iefix");

    @font-face {

        font-family: quote($name);

        @if $eot {
            src: url($font-path+ '/' +$eot); // IE9 Compat Modes
            $eot: url($font-path+ '/' +$iefont) unquote("format('eot')") unquote(','); // IE6-IE8
        } @else {
            $eot: unquote('');
        }

        @if $woff {
            $woff: url($font-path+ '/' +$woff) unquote("format('woff')") unquote(','); // Modern Browsers
        } @else {
            $woff: unquote('');
        }

        @if $truetype {
            $truetype: url($font-path+ '/' +$truetype) unquote("format('truetype')") unquote(','); // Safari, Android, iOS
        } @else {
            $truetype: unquote('');
        }

        @if $svg {
            $svg: url($font-path+ '/#{$svg}##{$name}') unquote("format('svg')"); // Legacy iOS
        } @else {
            $svg: unquote('');
        }

        src: $eot $woff $truetype $svg;

        @if $weight {
            font-weight: $weight;
        }

        @if $style {
            font-style: $style;
        }
    }
}


// Tiny version of the mixin. Only if we have all font file format (eot, woff, ttf and svg) and we use the same name of the file.
@mixin font-face-load-tiny($name, $filesname: false, $weight: false, $style: false) {

    $iefont: unquote("#{$filesname}.eot?#iefix");

    @font-face {
        font-family: quote($name);

        @if $filesname != false {
            src: url("#{$font-path}/#{$filesname}.eot"); // IE9 Compat Modes
            src: url("#{$font-path}/#{$iefont}") unquote("format('eot')"), // IE6-IE8
            url("#{$font-path}/#{$filesname}.woff") unquote("format('woff')"), // Modern Browsers
            url("#{$font-path}/#{$filesname}.ttf") unquote("format('truetype')"), // Safari, Android, iOS
            url("#{$font-path}/#{$filesname}.svg##{$name}") unquote("format('svg')"); // Legacy iOS
        }
        @if $weight {
            font-weight: $weight;
        }
        @if $style {
            font-style: $style;
        }
    }

}


// ========================================================================== 2. Mixins for Media Queries
/* -------------------------------------------------------------
 * Media Queries "min-width" [at-least($device-width)]
 ---------------------------------------------------------------*/

// GET MIXIN: @include at-least([$device-width]) { [@content] }
@mixin at-least($device-width) {
    @media only screen and (min-width: $device-width) {
        @content;
    }
}

@mixin min-screen($device-width) {
    @media only screen and (min-width: $device-width) {
        @content;
    }
}


/* -------------------------------------------------------------
 * Media Queries "max-width" [until($device-width)]
 ---------------------------------------------------------------*/

// GET MIXIN: @include until([$device-width]) { [@content] }
@mixin until($device-width) {
    @media only screen and (max-width: $device-width - 1) {
        @content;
    }
}

@mixin max-screen($device-width) {
    @media only screen and (max-width: $device-width - 1) {
        @content;
    }
}


/* -------------------------------------------------------------
 * Media Queries "max-width" and "min-width"
 ---------------------------------------------------------------*/
// @value: 'desktop-large', 'desktop', 'tablet' and 'mobile'.
// @default Value: 'desktop'

// GET MIXIN: @include for-device([$device]);
@mixin for-device($device:'desktop') {
    @if ($device == 'desktop-large') {
        @media only screen and (min-width: $desktop-large) {
            @content;
        }
    } @else if ($device == 'desktop') {
        @media only screen and (max-width: $desktop-large - 1) and (min-width: $desktop) {
            @content;
        }
    } @else if ($device == 'tablet') {
        @media only screen and (max-width: $desktop - 1) and (min-width: $tablet) {
            @content;
        }
    } @else if ($device == 'mobile') {
        @media only screen and (max-width: $tablet - 1) {
            @content;
        }
    }
}

// ========================================================================== 3. Mixins for Print

/* -------------------------------------------------------------
 * Media Queries for Print [print($value)]
 ---------------------------------------------------------------*/
// @value: boolean ('true' or 'false').
// @default Value: 'true'

// GET MIXIN: @include print([$value]);
@mixin print($value: true) {
    @if $value == true { // Add Specific CSS rules for the print display.
        @media only print {
            @content
        }
    } @else if ($value== false) { // Hide the current element for the print display. NB: You can use the shortcut mixin "no-rpint" (see below 3.2).
        @media only print {
            display: none;
        }
    }
}

/* -------------------------------------------------------------
 * Shortcut for hidde Element only for Print [no-print]
 ---------------------------------------------------------------*/
// The mixin "no-print" do the same action of mixin "@mixin print(false)".
// This mixin is for use like a shortcut to not display the current element for the print.

// GET MIXIN: @include no-print;
@mixin no-print {
    @media only print {
        display: none;
    }
}


// ========================================================================== 4. Mixins for Animation

/* -------------------------------------------------------------
 * Keyframes
 ---------------------------------------------------------------*/
// @value: 'text'

// GET MIXIN: @include keyframes([$name]) { [animation-content]}
@mixin keyframes($name) {
    @-webkit-keyframes #{$name} {
        @content;
    }
    @-moz-keyframes #{$name} {
        @content;
    }
    @-o-keyframes #{$name} {
        @content;
    }
    @keyframes #{$name} {
        @content;
    }
}


/* -------------------------------------------------------------
 * Single-Animation
 ---------------------------------------------------------------*/
// This mixin is only for an element with one animation.
// @value: 'text' & 'numeric'

// GET MIXIN: @include single-animation([$animationName], [$properties]);
@mixin single-animation($animationName, $property-1: false, $property-2: false, $property-3: false, $property-4: false, $property-5: false, $property-6: false, $property-7: false, $property-8: false, $property-9: false, $property-10: false) {

    $properties: compact($property-1 $property-2 $property-3 $property-4 $property-5 $property-6 $property-7 $property-8 $property-9 $property-10);

    -moz-animation: unquote(#{$animationName}) $properties;
    -webkit-animation: unquote(#{$animationName}) $properties;
    -o-animation: unquote(#{$animationName}) $properties;
    -ms-animation: unquote(#{$animationName}) $properties;
    animation: unquote(#{$animationName}) $properties;
}

@mixin animate($animation, $duration, $repeat, $easing) {
    -webkit-animation: $animation $duration $repeat $easing;
    -moz-animation: $animation $duration $repeat $easing;
    -ms-animation: $animation $duration $repeat $easing;
    animation: $animation $duration $repeat $easing;
}


// =============================================
// Mixin - Triangle
// =============================================

@mixin triangle($direction: up, $size: 5px, $color: #000000) {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    display: block;

    @if $direction == up {
        border-right: $size solid transparent;
        border-left: $size solid transparent;
        border-bottom: $size solid $color;
        border-top: none;
    }

    @if $direction == down {
        border-right: $size solid transparent;
        border-left: $size solid transparent;
        border-top: $size solid $color;
        border-bottom: none;
    }

    @if $direction == right {
        border-top: $size solid transparent;
        border-bottom: $size solid transparent;
        border-left: $size solid $color;
        border-right: none;
    }

    @if $direction == left {
        border-top: $size solid transparent;
        border-bottom: $size solid transparent;
        border-right: $size solid $color;
        border-left: none;
    }
}

// =============================================
// Mixin - Toggle Switch
// =============================================

@mixin toggle-switch {
    display: none;

    & + label {
        cursor: pointer;
        position: relative;
        display: inline-block;
        height: 2em;
        width: 4em;
        background: #ECECEC;
        border-radius: 20em;
        vertical-align: middle;
        font-size: 20px;

        &:before,
        &:after {
            position: absolute;
            top: 0;
            left: 0;
        }

        &:before {
            content: '';
            display: inline-block;
            background: rgba(0, 0, 0, .5);
            height: 1.5em;
            width: 1.5em;
            margin: .25em;
            border-radius: 50%;
            z-index: 200;
        }

        &:after {
            color: #fff;
            content: '\f00d';
            font: 1.25em 'FontAwesome';
            top: 50%;
            margin-top: -.5em;
            left: 2em;
        }
    }

    &:checked + label {
        background: $c-green;

        &:before {
            left: 2em;
        }

        &:after {
            content: '\f00c';
            left: .5em;
        }
    }
}
