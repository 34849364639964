
.#{$namespace}projects-grid {

    .#{$namespace}project-card {
        background-color: $c-white;
        border-radius: 5px;
        overflow: hidden;

       /* img {
            width: 100%;
            height: auto;
        }*/

        &-media {
            max-height: 200px;

            .uk-overlay-primary {
                opacity: 0;
                transition: opacity 0.15s;

                a {
                    color: $c-btn-default-color-contrast; //$c-btn-default-color;
                    border-color: $c-btn-default-border-contrast;

                    &:hover {
                        background-color: $c-btn-default-background-hover-contrast;
                        color: $c-btn-default-color-hover-contrast;
                    }
                }
            }
        }

        &-metas {
            padding: 10px 15px 15px 15px;
        }

        .#{$namespace}project-name {
            padding-top: 5px;
            font: {
                family: $base-font-family-bold ;
                size: 16px;
            }
            line-height: normal;
        }

        .#{$namespace}project-address {
            padding-top: 5px;
            font: {
                size: 11px;
            }
            line-height: normal;
        }

        &:hover {
            .#{$namespace}project-card-media {

                .uk-overlay-primary {
                    opacity: 1;
                    transition: opacity 0.25s;
                }
            }
        }

    }


    .#{$namespace}project-add {
        position: relative;
        display: block;
        height: 100%;
        min-height: 250px;
        border: 2px dashed $c-gray-light-alt; //$c-gray-light;
        border-radius: 5px;

        background-color: $c-gray-light; //$c-gray-lighter;

        text-align: center;

        color: $c-gray-light-alt; //$c-gray-light;
        opacity: 0.45;



         & > span {
            position: absolute;
            top: 50%;
            left: 50%;

           min-width: 160px;

            display: inline-block;

            font: {
                size: 22px;
            }
            text-align: center;

            transform: translate(-50%, -50%);

           svg {
               display: inline-block;
              /* width: 50px !important;
               height: 50px !important;*/


             & + span {
                margin-top: 10px;
                display: block;
             }
           }
        }
        //svg,
        &--icon {
            display: block;
            padding-bottom: 10px;

            span {
                display: inline-block;

                border: 1px solid $c-gray-light-alt; //$c-black;
                border-radius: 50%;

                width: 50px;
                height: 50px;

                line-height: 50px;
                text-align: center;
                font-size: 34px;

            }
        }

        &:hover {
            //color: $c-gray-light-alt;
            //border-color:  $c-gray-light-alt;
            //background-color: $c-gray-light; //darken($c-gray-light, 5%);

            opacity: 1;

            .#{$namespace}project-add--icon {

                span {
                    border: 2px solid $c-gray-light-alt;
                    background-color: $c-gray-light-alt;

                    color: $c-white;
                }
            }
        }
    }

}
