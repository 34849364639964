

.#{$namespace}link {
    &-delete {
        display: inline-block;
        color: $c-red;

        &:hover {
            color: $c-red;
        }
    }
}