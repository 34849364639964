.#{$namespace}helptips {
    display: block;
    margin: {
        top: 5px;
        bottom: 5px;
    }

    padding: 7px 10px;
    background-color: $c-gray-light;
    border: {
        left: 5px solid $c-gray-light-alt;
        radius: 3px;
    }


    color: $c-gray-light-alt;

    svg {
        max-width: 15px;
        margin-right: 10px;
    }
}