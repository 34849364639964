
%uk-alert-common {
    border-radius: 5px;
    padding: 20px 25px;

    background-color: rgba($c-gray, 0.15);
    border: {
        left: 7px solid $c-gray;
    }

    font-size: 16px;
    color: $c-green;
}

.uk-alert {

    &-close {
        opacity: 0.8;
    }

    &-primary {
        @extend %uk-alert-common;
        background-color: rgba($color-primary, 0.1);
        border-left-color: $color-primary;
        color: $c-white;

    }

    &-success {
        @extend %uk-alert-common;
        background-color: rgba($color-success, 0.1);
        border-left-color: $color-success;
        color: $color-success;
    }

    &-warning {
        @extend %uk-alert-common;
        background-color: rgba($color-warning, 0.1);
        border-left-color: $color-warning;
        color: $color-warning;
    }

    &-danger {
        @extend %uk-alert-common;
        background-color: rgba($color-danger, 0.1);
        border-left-color: $color-danger;
        color: $color-danger;

    }

}