

table,
.table
{
    thead {

        tr {

            th {
                font: {
                    family: $base-font-family;
                }

                &.#{$namespace}width-xsmall {
                    //max-width: 100px;
                    width: 100px;
                }
            }
        }
    }

    tbody {
        tr {
            td {
                vertical-align: middle;
                padding: 5px 10px; // 12px;

                &.#{$namespace}width-xsmall {
                    //max-width: 100px;
                    width: 100px;
                }
            }
        }
    }

}

table.table-responsive-block {

    @include max-screen($pages-bp-large) {
        width: auto;
        display: block;
        margin: {
            right: 15px;
            left: 15px;
        }
    }

    tbody {

        tr {

            td {


                @include max-screen($pages-bp-large) {
                    overflow: hidden;
                    border-bottom: 0;
                    padding: 3px 15px {
                        left: 37%;
                    }

                    &::before {
                        width: 35%;
                        padding-left: 10px;
                        color: $c-gray-light-alt;
                        font : {
                            weight: normal !important;
                        }
                    }

                    .#{$namespace}label-not-read {
                        margin-top: 10px;
                        float: right;
                    }
                }
            }
        }

    }

    &.table-responsive-no-title {
        tbody {

            tr {

                td {
                    @include max-screen($pages-bp-large) {
                        padding: 3px 15px;
                    }

                    &::before {
                        content: none;
                    }

                }
            }
        }


    }
}


.table.table-hover {
    tbody {
        tr:hover {

            td {
                background: none !important;

                @include min-screen($uk-bp-medium) {
                    //background-color: rgba($c-gray-light, 1) !important;
                    background-color: rgba($c-gray-lighter, 0.50) !important;
                }
            }
        }
    }
}

.dataTables_wrapper_responsive {
    display: block !important;

    @include max-screen($uk-bp-medium) {
        overflow-x: auto;

        background-image: linear-gradient(to right, white, white), linear-gradient(to right, white, white), linear-gradient(to right, rgba(0, 0, 20, .4), rgba(255, 255, 255, 0)), linear-gradient(to left, rgba(0, 0, 20, .4), rgba(255, 255, 255, 0));
        /* Shadows */
        /* Shadow covers */
        background-position: left center, right center, left center, right center;
        background-repeat: no-repeat;
        background-color: $c-white;
        background-size: 20px 100%, 20px 100%, 5px 100%, 5px 100%;
        background-attachment: local, local, scroll, scroll;

        .table tbody {
            tr {
                td {
                    background: none;
                }
                &:hover {
                    td {
                        background: none !important;
                    }
                }
            }

        }
    }



}

.dataTables_wrapper .dataTables_info,
.dataTables_wrapper .dataTables_paginate
{
    @include max-screen($pages-bp-large) {
        margin-top: 7px !important;
    }
}


