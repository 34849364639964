

body.page-files {


    .demo-table-dynamic thead th {

        &:nth-child(1),
        &:nth-child(2),
        &:nth-last-child(1)
        {
            width: auto;
        }

    }


    .demo-table-dynamic {

        tbody tr td {

            p {
                font-size: 12px;
            }

            p > i.fa,
            & > i.fa
            {
                font-size: 20px;
                color: $c-gray-light-alt;
            }
        }


        &.table-responsive-no-title {
            //background-color: $c-white;

            tbody {
                tr {

                    @include max-screen($pages-bp-large) {
                        position: relative;
                        background-color: $c-white;
                    }

                    td {
                       @include max-screen($pages-bp-large) {
                           box-sizing: border-box;
                           display: inline-block;

                           p {
                               margin: 0;
                           }

                           &:nth-of-type(1) {
                               width: 40px;
                           }

                           &:nth-of-type(2) {
                               width: calc(100% - 50px);
                           }

                           &:nth-of-type(3) {
                               clear: left;
                           }

                           &:nth-of-type(5) {
                               position: absolute;
                               top: 50%;
                               right: 5px;
                               display: inline-block;
                               transform: translateY(-50%);

                               p {
                                   margin: 0;
                               }
                           }

                           .btn {
                               padding: 10px;
                           }
                       }
                    }
                }
            }
        }
    }

    .#{$namespace}files-list {
        tr {
            .file-name {
                a {
                    font-weight: bold;
                }
                span {
                    color: $c-gray-light-alt;
                }
            }

            .#{$namespace}file-table-preview {
                position: relative;
                display: inline-block;
                width: 125px;
                height: 75px;
                overflow: hidden;

                //box-shadow: 5px 5px 15px -5px rgba($c-black, 0);

                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;

                    min-width: 125px;
                    min-height: 75px;

                    transform: translate(-50%, -50%);
                }

                &:hover {
                    box-shadow: 5px 5px 15px -5px rgba($c-black, 0.75);
                }
            }
        }
    }
}