
.card-group .card-header .card-title > a::after,
.card-group .card-header .card-title > a.collapsed::after
{
    font-size: 18px;
    margin-top: -1px;

    color: $c-collapse-icon-color; //$c-beige;
}

.card-group .card-header .card-title > a::after {
    content: '' !important;
    width: 20px;
    height: 20px;

    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='20' height='20' xmlns='http://www.w3.org/2000/svg' data-svg='chevron-down'%3E%3Cpath fill='none' stroke='%23000' stroke-width='1.03' d='M16 7l-6 6-6-6'/%3E%3C/svg%3E");
    transform: rotate(-180deg);
    transition: transform 0.25s;
}

.card-group .card-header .card-title > a.collapsed::after {
    transform: rotate(0deg);
}