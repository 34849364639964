/* ================================ */
/* Clearfixe
/* ================================ */

%clearfixe {
	&::after {
		content: "";
		display: table;
		clear: both;
	}
}

.#{$namespace}clearfixe {
	@extend %clearfixe;
}

/* ================================ */
/* Vertical alignment
/* ================================ */
.#{$namespace}vertical-align {
	@extend %vertical-align;
}

.#{$namespace}vertical-align-common {
	@extend %vertical-align-common;
}

.#{$namespace}vertical-align-middle {
	@extend %vertical-align-middle;
}

.#{$namespace}vertical-align-bottom {
	@extend %vertical-align-bottom;
}

/* ================================ */
/* Text alignment
/* ================================ */
.#{$namespace}align-center {
	text-align: center;
}

.#{$namespace}align-right {
	text-align: right;
}


/* ================================ */
/* Icons
/* ================================ */
.#{$namespace}icon-flipped {
	transform: scaleX(-1);
	-moz-transform: scaleX(-1);
	-webkit-transform: scaleX(-1);
	-ms-transform: scaleX(-1);
}



/* ================================ */
/* Show/Hidden
/* ================================ */
.#{$namespace}hidden {
	display: none !important;
	visibility: hidden;
}

.#{$namespace}show {
	display: block !important;
	visibility: visible;
}

/* ================================ */
/* Show/Hidden width break point
/* ================================ */
.#{$namespace}visible {
	 &-small {
		 display: none;
		 @include min-screen($bt-bp-small) {
			 display: block;
		 }
	 }
	 &-medium {
		 display: none;
		 @include min-screen($bt-bp-medium) {
			 display: block;
		 }
	 }
	 &-large {
		 display: none;
		 @include min-screen($bt-bp-large) {
			 display: block;
		 }
	 }
	 &-xlarge {
		 display: none;
		 @include min-screen($bt-bp-xlarge) {
			 display: block;
		 }
	 }
 }

.#{$namespace}hidden {
	&-small {
		display: block;
		@include min-screen($bt-bp-small) {
			display: none;
		}
	}
	&-medium {
		display: block;
		@include min-screen($bt-bp-medium) {
			display: none;
		}
	}
	&-large {
		display: block;
		@include min-screen($bt-bp-large) {
			display: none;
		}
	}
	&-xlarge {
		display: block;
		@include min-screen($bt-bp-xlarge) {
			display: none;
		}
	}
}

/* ================================ */
/* Display
/* ================================ */

.#{$namespace}display-inline-block {
	&-mobile{
		@include max-screen($bt-bp-large) {
			display: inline-block !important;
			width: auto !important;
		}
	}
	&-desktop{
		@include min-screen($bt-bp-large) {
			display: inline-block !important;
			width: auto !important;
		}
	}
}

.#{$namespace}display-block {
	&-mobile{
		@include max-screen($bt-bp-large) {
			display: block !important;
			width: 100% !important;
			min-height: auto !important;
			height: auto !important;
			min-width: 100% !important;
		}
	}
	&-desktop{
		@include min-screen($bt-bp-large) {
			display: block !important;
			width: 100% !important;
			min-height: auto !important;
			height: auto !important;
			min-width: 100% !important;
		}
	}
}

.#{$namespace}display-none {
	&-mobile{
		@include max-screen($bt-bp-large) {
			display: none !important;
			visibility: hidden !important;
		}
	}
	&-desktop{
		@include min-screen($bt-bp-large) {
			display: none !important;
			visibility: hidden !important;
		}
	}
}

/* ================================ */
/* Colors
/* ================================ */

.#{$namespace}bg {

	&-beige {
		background-color: $c-beige !important;
	}

	&-green {
		background-color: $c-green !important;
	}

	&-orange {
		background-color: $c-orange !important;
	}

	&-red {
		background-color: $c-red !important;
	}

	&-gray {
		background-color: $c-gray !important;
	}

	&-gray-light {
		background-color: $c-gray-light !important;
	}


}




/* ================================ */
/* Misc
/* ================================ */
.#{$namespace}padding-remove {
	padding: 0 !important;
}

.#{$namespace}margin-remove {
	margin: 0 !important;
}

.w-200 {
	width: 200px;
}

.danger {
	color: #F22B2B !important;
}
