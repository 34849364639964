
%rp-alert {
    border: 1px solid $c-gray;
    border-radius: 3px;

    background-color: $c-gray-lighter;
}


.#{$namespace}alert {
    &-info {
        @extend %rp-alert;
        border-color: $c-beige;
        background-color: rgba($c-beige, 0.10);
        padding: 10px 15px;

        color: $c-beige;
    }
}