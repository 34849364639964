
body.page-todo {

    .#{$namespace}table-todo {
        &-header {
            margin-top: 25px;

            .user-pic {
                display: inline-block;
                width: 40px;
                height: 40px;
                overflow: hidden;

                margin-right: 10px;

                border-radius: 50%;
                vertical-align: middle;
            }
            h2 {
                display: inline-block;
                vertical-align: middle;
                margin: 0;
                padding: 4px 0 0 0;

                height: 40px;

                letter-spacing: normal;

                @include max-screen($uk-bp-medium) {
                    font-size: 24px;
                }

                @include max-screen($uk-bp-small) {
                    font-size: 18px;
                }
            }
        }
    }

    .dataTables_wrapper + .#{$namespace}table-todo-header {
        margin-top: 75px;
    }


    /*.demo-table-dynamic thead th {

        &:nth-child(1),
        &:nth-child(2),
        &:nth-last-child(1)
        {
            width: auto;
        }

    }*/

    .table {
        thead {
            th {
                box-sizing: border-box !important;
                width: 100%;
                min-width: 200px;
                //max-width: 350px;

                &.status {
                    min-width: 55px;
                    width: 55px !important;
                    text-align: center;
                }

                &.buttons {
                    min-width: 55px;
                    width: 55px !important;
                }

                &.#{$namespace}table-todo-data-title {
                    min-width: auto;
                }
            }


        }
    }

    .table tbody tr {
        td .checkbox {
            margin: 0;

            label {
                margin: 0;
                padding-left: 0;

                &::before {
                    margin-right: 0;
                }

                &::after {
                    left: 4.5px;
                }

            }

        }

        &.is-done {

            .#{$namespace}table-todo-data-title {
                text-decoration: line-through;
            }
        }
    }

}

// Data Table for Tasks
/*.#{$namespace}data-table-tasks {
    table.data-table {
        thead {
            tr {
                th {
                    max-width: 124px;
                }
            }
        }
    }
}*/