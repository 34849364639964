
%uk-notification-common {
    position: relative;
    z-index: 2;

    border-radius: 5px;
    //padding: 20px 25px;

    background-color: rgba($c-gray, 0.15);
    border: 1px solid $c-gray-light {
        left: 7px solid $c-gray;
    }
    font-size: 16px;
    opacity: 1;
}

.uk-notification {


    &-message {
        /*
        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1;

            background: $c-white;
            border-radius: 5px;
        }*/
        &-primary {
            @extend %uk-notification-common;
            background-color: $color-primary-background; //rgba($color-primary, 0.1);
            border-left-color: $color-primary;
            color: $c-white;

        }

        &-success {
            @extend %uk-notification-common;
            background-color: $color-success-background; //rgba($color-success, 0.1);
            border-left-color: $color-success;
            color: $color-success;
        }

        &-warning {
            @extend %uk-notification-common;
            background-color: $color-warning-background; //rgba($color-warning, 0.1);
            border-left-color: $color-warning;
            color: $color-warning;
        }

        &-danger {
            @extend %uk-notification-common;
            background-color: $color-danger-background; //rgba($color-danger, 0.1);
            border-left-color: $color-danger;
            color: $color-danger;

        }
    }

}