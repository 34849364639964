

%heading-common {
    font: {
        family: $f-heading;
    }
}


h1, .h1 {
    @extend %heading-common;
    font-size: 38px; //42px;
    line-height: 1em;

    @include max-screen($uk-bp-medium) {
        font-size: 32px;
    }

    @include max-screen($uk-bp-small) {
        font-size: 28px;
    }
}

h2, .h2 {
    @extend %heading-common;
    font: {
        family: $base-font-family-bold;
        //weight: normal !important;
    }
    letter-spacing: 2px;

}

h3, .h3 {
    @extend %heading-common;
    font: {
        family: $base-font-family-light;
    }
}

h4, .h4 {
    @extend %heading-common;
}

h5, .h5 {
    @extend %heading-common;
}

h6, .h6 {
    @extend %heading-common;
}