

body.page-reports {


    .card .card-header .card-title {
        @include max-screen($pages-bp-medium) {
            display: block;
        }
    }




    .demo-table-dynamic thead th {

        &:nth-child(1),
        &:nth-child(2),
        &:nth-last-child(1)
        {
            width: auto;
        }

    }


    .demo-table-dynamic tbody tr td {
        p {
            font-size: 12px;
        }
    }

    .rp-reports-list {
        tr {
           &.is-not-read {

               td {
                   font: {
                       family: $base-font-family-bold;
                       //weight: bold;
                   }

                   p {
                       font: {
                           family: $base-font-family-bold;
                           //weight: bold;
                       }
                   }

                   &:nth-of-type(1) {
                       i {
                           color: $c-not-read;
                       }
                   }
               }

           }
        }
    }
}



body.page-report-details {

    .card .card-header .card-title {
        @include max-screen($pages-bp-medium) {
            display: block;
        }
    }

    .#{$namespace}sidebar-link {
        position: absolute;
        top: 50px;
        right: 40px;

        display: inline-block;

        a {
            position: relative;
        }
        i {
            position: relative;
            //top: 3px;
        }
    }
}

body.page-report-edit {

    .sortable-report-attendance-handle,
    .sortable-report-section-handle,
    .sortable-report-section-observation-handle
    {
        cursor: grab;

        &:active {
            cursor: grabbing;
        }
    }

    .uk-accordion-content {
        //margin: 0;
        //padding: 10px 0 25px 0; //15px 20px;
    }

    [data-rp-attendance],
    [data-rp-observation-status] {
        .form-group {
            margin-bottom: 0;
        }
    }

    .report-attendance-type,
    .report-observation-status {
        min-width: 80px;
        cursor: pointer;

        &.is-locked[disabled] {
            color: inherit;
        }

        &[data-rp-attendance='absent'],
        &[data-rp-observation-status='urgent'] {
            border-left: 3px solid $color-absent;
            background-color: rgba($color-absent, 0.05);
        }

        &[data-rp-attendance='present'],
        &[data-rp-observation-status='closed'] {
            border-left: 3px solid $color-present;
            background-color: rgba($color-present, 0.05);
        }

        &[data-rp-attendance='excused'],
        &[data-rp-observation-status='open'] {
            border-left: 3px solid $color-excused;
            background-color: rgba($color-excused, 0.05);
        }
    }

    .#{$namespace}report {
        &-container {
            margin-top: 25px;

            //background-color: $c-white;
            //padding: 20px 25px;

            .report-photo-mini {

                &-wrapper {
                    position: relative;
                    background-color: $c-white;
                    box-shadow: 5px 5px 15px rgba($c-black, 0.15);
                    //min-width: 110px;
                    border-radius: 3px;
                    overflow: hidden;

                    &--media {
                        a {
                            display: block;
                            width: 100%;
                            /*border: {
                                top-left-radius: 3px;
                                top-right-radius: 3px;
                            }*/
                            //overflow: hidden;
                        }

                        img {
                            width: auto;
                            min-width: 125px;
                            max-width: 200px;

                            height: auto;
                            min-height: 125px;
                            max-height: 200px;
                        }
                    }

                    &--text {
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;

                        opacity: 0;

                        padding: 0 5px; //7px 10px;

                        background-color: rgba($c-black-light,.8);

                        transition: opacity 0.15s;


                        & > div {
                            display: inline-block;
                            text-align: center;

                            & > span,
                            & > a
                            {
                                margin: 0 3px;
                            }

                            .btn.btn-default {
                                border-color: $c-white;
                                color: $c-white;

                                &:hover {
                                    background-color: $c-white;
                                    color: $c-black;
                                }
                            }
                        }
                    }

                    &:hover {
                        .report-photo-mini-wrapper--text {
                            opacity: 1;
                            transition: opacity 0.15s;
                        }
                    }

                }
            }
        }

        &-sub-section {
            position: relative;
            border: 1px solid $c-gray-light;
            border-radius: 5px;
            margin: 5px 0 5px 25px;
            padding: 20px;

            /*& + .#{$namespace}report-sub-section {
                border-top: 1px solid $c-gray-light;
            }*/

            .sortable-report-section-handle {
                position: absolute;
                top: 5px;
                left: -25px;
            }
        }

        &-section {
            &--opened-tasks,
            &--closed-tasks
            {
                &-list {
                    margin-top: 10px;
                }

                .#{$namespace}report-task-item {
                    padding: 5px 10px;

                    &.is-odd {
                        border-top: 1px solid #e5e5e5;
                        border-bottom: 1px solid #e5e5e5;
                        background: $c-gray-lighter-alt-secondary;

                        & + .is-even {
                            border-top: 0;
                        }
                    }
                    &.is-even {
                        border-top: 1px solid #e5e5e5;
                        border-bottom: 1px solid #e5e5e5;

                        & + .is-odd {
                            border-top: 0;
                        }
                    }
                }
            }

            &--closed-tasks {
                .is-done {
                    text-decoration: line-through;
                }
            }

            &--observations {
                &-table {
                    border-bottom: 1px solid #e5e5e5;

                    td {
                        padding: 10px;
                        border-top: 1px solid #e5e5e5;
                        //border-bottom: 1px solid #e5e5e5;

                        &:last-child {
                            text-align: left;
                        }
                    }
                    tr.is-odd {
                        & > td {
                            background-color: $c-gray-lighter-alt-secondary;
                        }
                    }

                    /*tr:last-child {
                        & > td {
                            border-bottom: 1px solid #e5e5e5;
                        }
                    }*/

                    * > .#{$namespace}report-section--observations-action--edit,
                    * > .#{$namespace}report-section-observations-comment-action--delete,
                    * > .#{$namespace}report-section-observations-comment-action--edit {
                        display: none;
                        //margin-left: 10px;
                        font: {
                            size: 11px;
                        }
                    }

                    *:hover > .#{$namespace}report-section--observations-action--edit {
                        display: block;
                    }

                    *:hover > .#{$namespace}report-section-observations-comment-action--delete,
                    *:hover > .#{$namespace}report-section-observations-comment-action--edit {
                        display: inline-block;
                    }

                    tr.report-section-actions {
                        & > td {
                            vertical-align: top;
                            padding: 10px 10px 20px 10px;

                        }
                    }
                }
                &-actions {
                    border-top-color: transparent !important;
                }



                &-id {
                    display: inline-block;
                    //min-width: 80px;
                    height: 26px;
                    line-height: 27px;
                    background: transparent; //darken($c-gray-lighter-alt-secondary, 10%);
                    border: 1px solid $c-gray-lighter-alt; //darken($c-gray-lighter-alt-secondary, 15%);
                    border-radius: 26px;
                    padding: 0 10px;

                    color: $c-gray-lighter-alt;

                    white-space: nowrap;

                    font: {
                        size: 13px;
                        weight: bold;
                    }

                    &.is-new,
                    &.is-updated {
                        background: $c-black;
                        border: 1px solid $c-black;
                        color: $c-gray-lighter-alt-secondary;
                    }
                }

                &-tag {
                    display: inline-block;
                    margin-right: 5px;
                    color: $c-white;
                    padding: 0 5px;
                    height: 20px;
                    line-height: 22px;
                    white-space: nowrap;

                    font: {
                        size: 12px;
                        weight: bold;
                    }
                }

                &-note {
                    em {
                        color: $c-black;
                    }
                    /*.#{$namespace}report-section--observations-action--edit {
                        display: none;
                        margin-left: 10px;
                    }

                    &:hover {
                        .#{$namespace}report-section--observations-action--edit {
                            display: inline-block;
                        }
                    }*/

                }

                &-add-note {
                    //text-align: center;
                    //background: darken($c-gray-lighter-alt-secondary, 2%);
                    //border: 2px dashed #e3e3e3;
                    //padding: 5px 5px;

                    //transition: all 0.15s;

                    .add-observation-btn {

                    }

                }

                &-attachments {
                    padding-top: 10px;

                    .#{$namespace}btn-attachment {
                        & + .#{$namespace}btn-attachment {
                            margin-left: 5px;
                        }
                    }
                }
            }
        }
    }


    .uk-list-striped {


        & > li {
            padding: 5px 10px;
            line-height: normal;

        }

        /*&[class*='uk-column-1-2'] {
            & > li {
                display: block;
                box-sizing: border-box;
                padding: 0 10px;
                border: 0;

                & > * {
                    padding: {
                        top: 5px;
                        bottom: 5px;
                    }
                    border: {
                        top: 1px solid red;
                        bottom: 0; //1px solid green;
                    }
                }
            }
        }*/

    }


    ul.report-section-observations {
        padding-left: 0;
    }

    .report-section-observation {
        position: relative;
        margin: {
            left: 25px;
            bottom: 10px;
        }
        padding: 20px;
        //border: 1px solid $c-gray-lighter-alt;
        background-color: $c-gray-lighter-alt-secondary;

        .sortable-report-section-observation-handle {
            position: absolute;
            top: 5px;
            left: -25px;
        }

        .report-section-observation-actions {
            position: absolute;
            top: 20px;
            right: 20px;
        }

        input[type="checkbox"] {
            @include toggle-switch;
        }

        &-delete-btn {
            color: $color-danger;
            &:hover {
                color: rgba($color-danger, 0.5);
            }
        }
    }


    .report-section-observation-actions {
        text-align: right !important;

        input[type="checkbox"] {
            @include toggle-switch;
        }
    }

    .#{$namespace}section-add-actions {
        margin-left: 25px;
        padding: 20px;
        border: 2px dashed $c-gray-light; //rgba($c-gray-light, 0.75);
        border-radius: 5px;
        background-color: $c-gray-lighter-alt-secondary; //rgba($c-gray-lighter-alt-secondary, 0.75);
    }

    #report-signatures {
        margin-top: 10px;
    }

    .report-signature {
        text-align: center;
        padding-top: 5px;

        &-canvas {
            margin-top: 5px;
            border: 1px solid $c-gray-lighter-alt;
            border-radius: 5px;
            background-color: $c-white;
            width: 250px;
            height: 150px;
        }
    }


    .report-users-to-notify {
        input[type="checkbox"] {
            @include toggle-switch;
        }
    }

    .report-attachment-delete-btn > i {
        vertical-align: middle;
    }

    .sidebar-confirm-btn, .report-section-delete-btn {
        top: 20px;
    }
}
