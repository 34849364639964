.#{$namespace}sidebar {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1001;

    width: 90%;
    max-width: 800px;
    height: 100%;

    overflow: auto;

    background-color: $c-white;
    border-left: 1px solid $c-gray-light;

    font: {
        size: 13px;
    }

    transform: translateX(100%);
    transition: all 0.15s;

    &.is-open {
        transform: translateX(0);
        transition: all 0.25s;
    }

    &-overlay,
    &-overlay-add-report {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 899;

        background-color: $c-black;
        filter: alpha(Opacity=80);
        opacity: .8;
        display: none;

        cursor: pointer;
    }

    &-header {
        @extend %clearfixe;
        position: sticky;
        top: 0;
        z-index: 1;

        width: 100%;
        border-bottom: 1px solid $c-gray-light;
        background-color: $c-white;

        padding: 20px 25px 10px 25px;

        h1, h2, h3, h4, h5, h6 {
            float: left;
            display: inline-block;
            margin: 0;

            & + * {
                margin-left: 20px;
            }
        }

        .#{$namespace}sidebar-close {
            position: absolute;
            right: 0; //25px;
            top: 0; //50%;

            display: inline-block;
            //width: 34px;
            //height: 34px;

            //font-size: 34px;
            //line-height: normal;

            //transform: translateY(-50%);
        }
    }


    &-content {
        padding: 20px 0 45px 0; //20px 25px 45px 25px;
    }

    .ck-editor__editable {
        min-height: 150px;
    }
}
