

// Mixins
// --------------------------------------------------

// Creates contextual buttons
@mixin button($text-color, $background-color, $border-color) {
    &,
    &:focus{
        color: $text-color;
        background-color: $background-color;
        border-color: $background-color;
    }

    &.active,
    &:active,
    &.active:focus,
    &:active:focus,
    &:active:hover,
    .show .dropdown-toggle#{&} {
        background-color: mix($background-color, $c-black, $mix-percentage-dark);
        border-color: mix($background-color, $c-black, $mix-percentage-dark);
        color:$text-color;
    }
    &.hover,
    &:hover,
    .show .dropdown-toggle#{&}
    {
        background-color: mix($background-color, $c-white, $mix-percentage-light);
        border-color: mix($background-color, $c-white, $mix-percentage-light);
        color: $text-color;
    }
    &.active:hover{
        background: mix($background-color, #000, 86%) ;
        border-color: mix($background-color, #000, 86%) ;
    }

    &.disabled,
    &[disabled],
    fieldset[disabled] & {
        &,
        &:hover,
        &:focus,
        &:active,
        &.active {
            background-color: $background-color;
            border-color: $border-color;
        }
    }

    .badge {
        color: $background-color;
        background-color: $text-color;
    }
}

[type=reset],
[type=submit],
button,
html [type=button]
{
    -webkit-appearance: none;
}

.btn:not(.dropdown-toggle),
.uk-button:not(.dropdown-toggle),
.uk-button.uk-button-default,
.#{$namespace}button,
.uk-offcanvas-close
{
    position: relative;
    box-sizing: border-box;
    //padding-top: 10px;

    font-family: $f-body;
    font: {
        size: 15px;
        weight: normal;
    }
    padding: 0 20px;
    letter-spacing: 0.01em;
    border: 2px solid $c-btn-default-border {
      radius: 21px;
    }
    height: 42px;
    min-width: 42px;
    line-height: 40px;
    /*display: flex;
    align-items: center;*/


    color: $c-btn-default-color; //$c-beige;
    background-color:  transparent; //$c-white;

    @include max-screen($uk-bp-small) {
        padding: 0 15px;
        font-size: 13px;

    }

    &.btn-xs {
        padding: 0 10px;

        .pg-close {
            position: absolute;
            top: calc(50%);
            left: calc(50% + 1px);

            font-size: 32px;

            transform: translate(-50%, -50%);
        }
    }

    &.#{$namespace}button-close {
        border: none;

        color: $c-btn-close-color;

        &:hover {
            border: 0;
            background-color: transparent;

            color: $c-btn-close-color-hover;
        }

        &.uk-offcanvas-close {
            position: absolute;
            top: 20px;
            right: 20px;

            svg[data-svg="close-icon"] {
                display: none;
                visibility: hidden;
            }
        }
    }

    &.btn-small {
        font-size: .9em;
        height: 25px;
        line-height: 25px;
        padding: 0 10px;
    }

    i {
        & + span {
            margin-left: 5px;
            @include max-screen($pages-bp-medium) {
                display: none;
            }
        }
    }

    svg {
        &[data-icon="chevron-left"] {
            width: 8px;
        }

        & + span {
            margin-left: 5px;
        }
    }




    &:hover{
        background-color: $c-btn-default-background-hover; //$c-beige;
        //border: 1px solid fade-out($c-white,.73);
        color: $c-btn-default-color-hover; //$c-white;
        border: 2px solid $c-btn-default-border {
          radius: 21px;
        }
    }
    &.active{
        border-color: $c-btn-default-border;
        background: $c-btn-default-background-hover;
        color: $c-btn-default-color-hover;
    }
   /* &:focus,
    &:active:focus,
    &.active:focus {
        outline: none !important;
        outline-style: none;
        color: #333;
    }
    .caret {
        margin-left: 3px;
        &.single {
            margin-left: 0px;
        }
    }
    &:hover,
    &:focus,
    &:active,
    &.active,
    &.disabled,
    &[disabled] {
        box-shadow: none;
    }*/

    .#{$namespace}button-count {
        position: absolute;
        top: -7px;
        right: -7px;

        display: block;
        min-width: 24px;
        height: 24px;
        padding: 0 2px;
        box-sizing: border-box;

        background: $c-bullet-count-background;
        border: 2px solid $c-bullet-count-border;
        border-radius: 24px;

        text-align: center;
        line-height: 22px;
        color: $c-bullet-count-color;
        font-size: 10px;
    }

    .#{$namespace}button-count.all-seen-by-user {
        background: invert($c-bullet-count-background);
        border-color: invert($c-bullet-count-border);
        color: invert($c-bullet-count-color);
    }


    /*
Alternate buttons
--------------------------------------------------
*/


    &.btn-primary,
    &.uk-button-primary
    {
        @include button($c-white, $color-primary, $color-primary);
        //border-radius: 20px;
    }
    &.btn-success {
        @include button($c-white, $color-success, $color-success);
    }
    &.btn-complete{
        @include button($c-white, $color-complete, $color-complete);
    }
    &.btn-info {
        @include button($c-white, $color-info, $color-info);
    }
    &.btn-warning {
        @include button($c-white, $color-warning, $color-warning);
    }
    &.btn-danger {
        //@include button($c-white, $color-danger, $color-danger);
        border-color: $color-danger;
        background: transparent; //$c-white;
        color: $color-danger;

        .fa.fa-trash {
            margin-top: 10px;
            font-size: 18px;
        }

        &:hover {
            border-color: $color-danger;
            background: $color-danger;
            color: $c-white;
        }
    }
}
// Fix for chrome's button outline on focus
button:focus{
    outline: none !important;
}


.dropdown-toggle {
    color: $c-gray-light-alt;
    background-color: transparent;
    border-color: $c-gray-light-alt;

    &:hover {
        color: $c-gray-light-alt;
        background-color: transparent;
        border-color: $c-gray-light-alt;
    }
}

/*
.btn-default, .btn-default:focus{
    color: #5e5e5e;
    background-color: $c-white;
    border-color: $color-master-lighter;
}
.btn-default {
    &.active, &:active, &.active:focus, &:active:focus, &:active:hover{
        background-color: $color-master-lighter;
        border-color: $color-master-light;
        color:$color-master-dark;
    }
    &.hover, &:hover{
        background-color: $color-master-lightest;
        border-color: fade-out($color-master,.73);
        color: $color-master-darker;
    }
    &.active:hover{
        background: mix($c-white, #000, 94%) ;
    }
}*/

.btn-group-sm > .btn,
.btn-sm
{
    font-size: 12px;
    padding: {
        top: 6px;
        bottom: 4px;
    }
}

.btn-lg,
.btn-group-lg > .btn
{
    padding: {
        top: 14px;
        bottom: 12px;
    }
}

.btn-toolbar .btn {
    padding: {
        top: 12px;
        bottom: 10px;
    }
}

.btn-default.active,
.btn-default:active,
.btn-default.active:focus,
.btn-default:active:focus,
.btn-default:active:hover,
.btn-default:focus
{
    background-color: $c-btn-default-active-background; //$c-beige;
    color: $c-white;
    border-color: $c-btn-default-active-border; //$c-beige;
}

.#{$namespace}btn-attachment {
    position: relative;
    display: inline-block;
    height: 22px;
    padding: 0 0 0 0;
    background: $c-gray-dark;
    border-radius: 11px;
    overflow: hidden;

    color: $c-white;
    line-height: 22px;
    font: {
        size: 12px;
    }

    .action-delete-image {
        display: inline-block;
        margin-right: 5px;
        cursor: pointer;
        overflow: hidden;

        i {
            position: relative;
            z-index: 2;


            background: $c-gray-darker;
            width: 22px;
            height: 22px;

            border-radius: 11px;
            line-height: 22px;

            text-align: center;

        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: -100%;
            z-index: 1;

            display: inline-block;
            height: 22px;
            width: 100%;

            border-radius: 11px;
            background: $color-danger;

            transition: left 0.15s;
        }

        &:hover {

            i {
                background: darken($color-danger, 10%);
            }

            &::before {
                left: 0;
                transition: left 0.25s;
            }
        }
    }

    .action-edit-image {
        display: inline-block;
        margin-left: 5px;
        cursor: pointer;
        overflow: hidden;

        i {
            position: relative;
            z-index: 2;


            background: $c-gray-darker;
            width: 22px;
            height: 22px;

            border-radius: 11px;
            line-height: 22px;

            text-align: center;

        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            right: -100%;
            z-index: 1;

            display: inline-block;
            height: 22px;
            width: 100%;

            border-radius: 11px;
            background: $c-gray-darker;

            transition: left 0.15s;
        }

        &:hover {

            i {
                background: darken($c-gray-darker, 10%);
            }

            &::before {
                right: 0;
                transition: right 0.25s;
            }
        }
    }

    a {
        color: $c-white;

        span {
            position: relative;
            z-index: 2;

            &:not(.action-delete-image) {
                overflow: hidden;
                white-space: nowrap;
                max-width: 230px;
                display: inline-block;
                text-overflow: ellipsis;
            }
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1;
            opacity: 0;

            background: $c-btn-default-background-hover;

            color: $c-btn-default-color-hover;

            transition: opacity 0.15s;
        }

        &:hover {
            &::before {
                opacity: 1;

                transition: opacity 0.25s;
            }
        }
    }
}


// -----------------
// Button More
// -----------------
.uk-button.#{$namespace}button-more {
    position: relative;
    border-color: $c-btn-more-border;
    background-color: $c-btn-more-background;
    color: $c-btn-more-color;
    padding: 0;
    width: 42px;
    height: 42px;

    text-align: center;
    line-height: 42px;

    .#{$namespace}button-more-count {
        position: absolute;
        top: -7px;
        right: -7px;

        display: block;
        min-width: 24px;
        height: 24px;
        padding: 0 2px;
        box-sizing: border-box;

        background: $c-bullet-count-background;
        border: 2px solid $c-bullet-count-border;
        border-radius: 24px;

        text-align: center;
        line-height: 22px;
        color: $c-bullet-count-color;
        font-size: 10px;
    }

    &.uk-open {
        border-color: $c-btn-more-border-open;
        background-color: $c-btn-more-background-open;
        color: $c-btn-more-color-open;
    }
}

.uk-button.#{$namespace}button-user {
    position: relative;
    border-color: transparent;
    background-color: $c-gray-lighter;
    color: $c-black-light;
    padding: 0;
    margin: 4px 5px 4px 0;
    width: 34px;
    height: 34px;
    min-width: 34px;
    line-height: 34px;
    font-size: 12px;
    text-align: center;
    cursor: default;
}

// -----------------
// Button Comment
// -----------------
.btn.addCommentLink {
    box-sizing: border-box;

    border-width: 1px;
    height: 32px;
    min-width: 32px;
    padding: 0 3px;

    line-height: 32px;
    font-size: 14px;

    &:hover {
        border-width: 1px;
    }

    &:not(.all-seen-by-user) {
        background-color:  $c-btn-comment-link-all-seen-by-user-background;
        color:  $c-btn-comment-link-all-seen-by-user-color;
    }

}
