$point-size: 16px;


#comments-channel-switch {
    &.uk-subnav > * {
        padding-left: 0;
    }
}

.#{$namespace}comments {

    &-count {
        display: inline-block;
        float: left;
        box-sizing: border-box;
        width: 28px;
        height: 28px;
        margin-left: 15px;


        padding: 0;
        //background-color: $c-gray;
        border-radius: 50%;

        text-align: center;
        font-size: 15px;
        line-height: 28px;
        //color: $c-white;
        //padding-top: 2px;

    }

    &_list {

        /*padding: {
            right: $point-size;
            left: $point-size;
        }*/
    }


    .#{$namespace}comment {
        position: relative;
        padding: 10px 25px 15px 25px;

        &-empty {
            text-align: center;
            font: {
                size: 16px;
            }
            color: $c-gray-lighter-alt;

            i {
                font-size: 100px;
                margin-bottom: 25px;
                color: rgba($c-gray-lighter-alt, 0.5);
            }
        }

        &-wrapper {
            position: relative;
            background-color: $bg-color-comment;
            border-radius: .4em;
            //box-shadow: 0 1px 1px rgba($c-gray, 0.3);

            margin: {
                right: 105px;
                left: 65px;
            }

            padding: 15px 15px 5px 15px;
            font-size: 13px;

            &:after {
                content: '';
                position: absolute;
                left: 0;
                top: 25px;
                width: 0;
                height: 0;
                border: $point-size solid transparent;
                border-right-color: $bg-color-comment;
                border-left: 0;
                border-bottom: 0;
                margin-top: -($point-size / 2);
                margin-left: -$point-size;
            }
        }

        p {
            font-size: 13px;
        }

        .user-pic {
            position: absolute;
            left: -65px;
            top: 15px;

            width: 40px;
            height: 40px;
            border-radius: 50%;
            overflow: hidden;
        }

        &-header {
            padding-bottom: 25px;
        }

        &-user-name {
            display: inline-block;
            font-weight: bold;
            padding-top: 7px;
            //padding-bottom: 5px;


            font-size: 15px;
            color: $c-gray;

            small {
                color: $c-gray-light-alt;
                font-size: 11px;
            }
        }

        &-actions {
            display: inline-block;
            float: right;

            a {
                display: inline-block;
                padding: 0px 7px;
                border: 1px solid $c-btn-default-border;

                height: 28px;
                line-height: 30px;
                border-radius: 14px;
                box-sizing: border-box;
            }
        }

        &-metas {
            //position: absolute;
            //bottom: -20px;
            margin-top: 10px;
            text-align: right;

            font-size: 11px;
            color: $c-gray-light-alt;
        }



        &-notification {
            position: relative;

            margin: 10px 25px 15px 25px;
            border-radius: 5px;
            padding: 20px 25px 20px 65px;
            background-color: $c-gray-light;
            overflow: hidden;
            font-size: 16px;

            &-icon {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;

                display: block;
                width: 50px;

                text-align: center;

                background-color: darken($c-gray-light, 5%);

                i {
                    position: absolute;
                    top: 50%;
                    left: 50%;

                    font-size: 28px;

                    transform: translate(-50%, -50%);
                }

            }

            .#{$namespace}comment-metas {
                margin-top: 0;
                position: absolute;
                right: 15px;
                bottom: 5px;
            }
        }




        &.is-you {


            .#{$namespace}comment {
                &-wrapper {
                    //background-color: rgba($c-beige,0.2);
                    background-color: $bg-color-comment-is-you;
                    margin: {
                        right: 65px;
                        left: 105px;
                    }
                    color: $text-color-comment-is-you;

                    &:after {
                        left: auto;
                        right: 0;
                        border-left: $point-size solid $bg-color-comment-is-you;
                        border-right-color: transparent;
                        border-right: 0;
                        border-bottom: 0;
                        margin-top: -($point-size / 2);
                        margin-right: -$point-size;
                    }
                }

                &-user-name {
                    color: $c-white;

                    small {
                        color: $c-gray-light-alt;
                    }
                }

                &-actions {
                    a {
                        border-color: $c-btn-default-border-contrast;
                        color: $c-btn-default-color-contrast;
                    }
                }

            }

            .user-pic {
                left: auto;
                right: -65px;
            }

        }


    }

    &_add {
        margin-top: 45px;
        margin-bottom: 75px;
        border-top: 1px solid $c-gray-light;
        padding: {
            top: 25px;
            right: 25px;
            left: 25px;
        }

        .#{$namespace}actions {
            padding: {
                top: 10px;
            }
            text-align: right;
        }
    }

}

.modal-add-todo-from-comment {
    h5 {
        height: auto;
    }
}

.sidebar-temp-content {
    height: 100%;

    .rp-comments {
        height: 100%;
        overflow-y: auto;
    }
}
