
.#{$namespace}document {

    &-list {
        margin: {
            top: 25px;
        }
    }

    &-folder {
        background-color: $c-white;

        .uk-accordion-title {
            /*padding: 15px 25px;
            vertical-align: middle;


            &::before {
                border: 1px solid $c-link;
                border-radius: 50%;
            }*/

            .fa-folder-open-o {
                display: none;
                visibility: hidden;
            }

            .fa-folder-o {
                display: inline-block;
                visibility: visible;
            }
        }

        /*.uk-accordion-content {
            padding: 20px;
        }*/

        table {
            margin: 0 !important;
        }

        &.uk-open {
            & > .uk-accordion-title > div > div {
                /*background-color: $c-gray;
                color: $c-white;

                &::before {
                    //color: $c-white;
                    border-color: transparent;
                    background-color: $c-white;
                    border-radius: 50%;
                }*/

                & > .fa-folder-open-o {
                    display: inline-block;
                    visibility: visible;
                }

                & > .fa-folder-o {
                    display: none;
                    visibility: hidden;
                }

            }
        }
    }

    &-add {
        /*#image-preview:empty {
            display: none;
            visibility: hidden;
        }*/
        #documentFileName:not(:empty){
           padding: {
               top: 15px;
               bottom: 25px;
           }
           font: {
               size: 16px;
           }
           i {
               font-size: 24px;
           }
        }

        &-step {
            padding: 0 25px;

            & > div {
                padding: 15px 0 30px 0;
                border-bottom: 1px solid $c-gray-light;

                &:nth-last-of-type(1) {
                    border-bottom: 0 none;
                }
            }

            h3 {
                padding-bottom: 15px;
            }

            &-number {
                position: relative;
                top: -3px;

                display: inline-block;
                width: 30px;
                height: 30px;
                margin: {
                    //top: -10px;
                    right: 5px;
                }

                background-color: $color-primary;
                border-radius: 50%;

                font: {
                    size: 18px;
                }
                text-align: center;
                line-height: 34px;
                color: $c-white;
            }
        }

        &-folder-add {
            padding-top: 10px;
        }
    }



}

/************************************************************************************************
Document index
*************************************************************************************************/
/*
.folder-row h3{
    text-align: left;
    text-transform: uppercase;
}*/



/************************************************************************************************
Document upload (sidebar)
*************************************************************************************************/

.documentUploadWrapper {
    //margin-top:10px;
    //background-color: white;
    //padding: 20px;
    //display: none;

   /* .row{
        margin-top: 10px;
    }*/


    .image-preview {
        margin-right: 15px;
        border-radius: 5px;
        padding: 20px;
        background-color: $c-gray-light;
    }

    .newDocumentAction{
        text-align: right;
    }

    .custom-file{
        display: none;
    }

}
