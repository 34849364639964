
$namespace          : 'rp-' !default; //namespace (default : null)
$img-path           : "../../../img/" !default;
$font-path          : '../../fonts/' !default;

/* ------------------------------------------------
 * Colors
 --------------------------------------------------*/
//Primary Colors
$c-blue-light	: #41c1e4;
$c-gray-dark	: #515661;
$c-gray-darker	: #313131;
$c-gray 		: #4b4b4b;
$c-gray-light	: #e3e3e3;
$c-gray-light-alt	: #9c9c9c;
$c-gray-lighter : #f4f4f4;
$c-gray-lighter-alt	: #959595;
$c-gray-lighter-alt-secondary: #f8f8f8;
$c-beige        : #bea67c;

$c-black      	: #000000;
$c-black-light  : #202020;
$c-black-alt    : rgba(0, 0, 0, 0.87);
$c-white      	: #FFFFFF;

// Secondary Colors
$c-green		: #4cb98f;
$c-blue			: #006fc4;
$c-red			: #F22B2B;

$c-turquoise    : #10cfbd;


$c-blue-gray	: #687888;
$c-orange		: #f08828;
$c-blue-dark  	: #1b223d;
$c-red-alt		: #d0021b;
$c-red-light	: #f55753;


// Additional Colors
$c-ultraviolet	: #7f1ae5;
$c-purple	: #742fe5;

// Colors application
$c-not-read     : $c-black; //$c-gray-lighter; //$c-black; //$c-beige;
$c-private      : $c-gray-lighter;

$c-state-hover  : $c-gray;
$c-state-active : $c-black; //$c-beige;
$c-state-active-contrast : $c-white;

$color-success: $c-turquoise !default;
$color-success-background: #EFF4F2 !default;
$color-complete : #48b0f7 !default;
$color-complete-background : #dfeff7 !default;
$color-primary: $c-black !default; //$c-beige !default;
$color-primary-background: $c-black !default;
$color-warning: #f8d053 !default;
$color-warning-background: #F7F2ED !default;
$color-danger: $c-red !default;
$color-danger-background: #F7EDED !default;
$color-info: #3b4752 !default;

$color-absent: $c-red !default;
$color-present: $c-green !default;
$color-excused: $c-orange !default;

$c-pluriell-red: #f93401 !default;

$color-customer : $c-gray-lighter !default;
$color-contractor : $c-gray-lighter !default;


// Selection (::selection)
$c-selection-default-background : $c-black;
$c-selection-default-color : $c-white;


// Link
$c-link         : $c-black; //$c-beige;
$c-link-disabled: rgba($c-black, 0.25);
$c-link-hover   : lighten($c-black, 15%); //lighten($c-beige, 15%);
$c-link-active  : $c-black; //$c-beige;

$c-link-contrast         : $c-white;
$c-link-hover-contrast   : darken($c-white, 15%);
$c-link-active-contrast  : $c-white; //$c-beige;

// Bullet count
$c-bullet-count-background: $c-black;
$c-bullet-count-border: $c-white;
$c-bullet-count-color: $c-white;


// Button
$c-btn-default-border: $c-black;
$c-btn-default-color: $c-black;
$c-btn-default-border-contrast: $c-white;
$c-btn-default-color-contrast: $c-white;

$c-btn-default-background-hover: $c-black;
$c-btn-default-color-hover: $c-white;
$c-btn-default-background-hover-contrast: $c-white;
$c-btn-default-color-hover-contrast: $c-black;

$c-btn-default-active-background: $c-black;
$c-btn-default-active-border: $c-black;
$c-btn-default-active-color: $c-white;

    // Btn Type More
    $c-btn-more-border: $c-gray-lighter-alt;
    $c-btn-more-color: $c-gray-lighter-alt;
    $c-btn-more-background: transparent;

    $c-btn-more-border-open: $c-gray-lighter-alt;
    $c-btn-more-color-open: $c-white;
    $c-btn-more-background-open: $c-gray-lighter-alt;


    // Btn Comment
    $c-btn-comment-link-all-seen-by-user-background: $c-black;
    $c-btn-comment-link-all-seen-by-user-color: $c-white;

    // Btn Type Close
    $c-btn-close-color: $c-gray;
    $c-btn-close-color-hover: $c-black;



// Tab
$c-tab-border         : transparent;
$c-tab-border-hover   : $c-black; //$c-gray;
$c-tab-border-active  : $c-state-active;


// Type
$c-type-note     : $c-black; //$c-gray-light-alt;
$c-type-comment  : $c-black; //$c-purple;
$c-type-report   : $c-black; //$c-orange;
$c-type-file     : $c-black; //$c-blue;
$c-type-task     : $c-black; //$c-green;

$c-type-pdf   : #cc4a4c;
$c-type-word  : #2b5796;
$c-type-excel : #1e6d42;

// Page progress
$c-page-progress : $c-pluriell-red; //$c-white;

// Progress Bar
$c-progress-bar: $c-black;

// DatePicker
$c-datepicker-next-prev: $c-black;
$c-datepicker-dow: $c-black;
$c-datepicker-day-selected: $c-black;

// Dropdown
$c-dropdown: $c-white;  // Background
$c-dropdown-color: $c-black; // Font color
$c-dropdown-hover : $c-black; // Background Hover
$c-dropdown-hover-color: $c-white; // Font color Hover

// Checkbox
$c-checkbox-bg-checked: $c-black; // Background checkbox:checked

// Collapse
$c-collapse-icon-color : $c-black;

// Comments (_comments.scss)
$bg-color-comment: $c-gray-lighter;
$bg-color-comment-is-you : $c-black; //lighten($c-beige, 32%);
$text-color-comment-is-you: $c-white;

// Page Planning (_page-planning.scss)
$c-planning-today-color: $c-black;

// Animations (_animations.scss)
$c-animation-highlight-background : rgba($c-gray-light, 0.8); //rgba($c-turquoise, 0.4); //$c-black;



//Mixing Color varriable for creating both light and dark colors
$mix-percentage-dark: 81% !default;
$mix-percentage-darker: 60% !default;
$mix-percentage-light: 80% !default;
$mix-percentage-lighter: 20% !default;


/* ------------------------------------------------
 * Font stacks
 --------------------------------------------------*/

@font-face {
    font-family: 'Helvetica Neue LT Std Hv';
    src: url('#{$font-path}HelveticaNeueLTStd/HelveticaNeueLTStd-Hv.eot');
    src: url('#{$font-path}HelveticaNeueLTStd/HelveticaNeueLTStd-Hv.eot?#iefix') format('embedded-opentype'),
    url('#{$font-path}HelveticaNeueLTStd/HelveticaNeueLTStd-Hv.woff2') format('woff2'),
    url('#{$font-path}HelveticaNeueLTStd/HelveticaNeueLTStd-Hv.woff') format('woff'),
    url('#{$font-path}HelveticaNeueLTStd/HelveticaNeueLTStd-Hv.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica Neue LT Std Lt';
    src: url('#{$font-path}HelveticaNeueLTStd/HelveticaNeueLTStd-Lt.eot');
    src: url('#{$font-path}HelveticaNeueLTStd/HelveticaNeueLTStd-Lt.eot?#iefix') format('embedded-opentype'),
    url('#{$font-path}HelveticaNeueLTStd/HelveticaNeueLTStd-Lt.woff2') format('woff2'),
    url('#{$font-path}HelveticaNeueLTStd/HelveticaNeueLTStd-Lt.woff') format('woff'),
    url('#{$font-path}HelveticaNeueLTStd/HelveticaNeueLTStd-Lt.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica Neue LT Std Bd';
    src: url('#{$font-path}HelveticaNeueLTStd/HelveticaNeueLTStd-Bd.eot');
    src: url('#{$font-path}HelveticaNeueLTStd/HelveticaNeueLTStd-Bd.eot?#iefix') format('embedded-opentype'),
    url('#{$font-path}HelveticaNeueLTStd/HelveticaNeueLTStd-Bd.woff2') format('woff2'),
    url('#{$font-path}HelveticaNeueLTStd/HelveticaNeueLTStd-Bd.woff') format('woff'),
    url('#{$font-path}HelveticaNeueLTStd/HelveticaNeueLTStd-Bd.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica Neue LT Std Md';
    src: url('#{$font-path}HelveticaNeueLTStd/HelveticaNeueLTStd-Md.eot');
    src: url('#{$font-path}HelveticaNeueLTStd/HelveticaNeueLTStd-Md.eot?#iefix') format('embedded-opentype'),
    url('#{$font-path}HelveticaNeueLTStd/HelveticaNeueLTStd-Md.woff2') format('woff2'),
    url('#{$font-path}HelveticaNeueLTStd/HelveticaNeueLTStd-Md.woff') format('woff'),
    url('#{$font-path}HelveticaNeueLTStd/HelveticaNeueLTStd-Md.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

//$f-stack    : "Roboto", "Helvetica", "Arial", sans-serif !default;
$base-font-family    : "Helvetica Neue LT Std Md", Helvetica, Arial, sans-serif !default;
$base-font-family-light    : "Helvetica Neue LT Std Lt", Helvetica, Arial, sans-serif !default;
$base-font-family-bold    : "Helvetica Neue LT Std Bd", Helvetica, Arial, sans-serif !default;
//$f-special 	: 'Adamina', serif !default;
//$f-alt		: 'Oswald', 'Muli', Arial, sans-serif !default;
//$f-icon     : 'FontAwesome' !default;

$f-body         : $base-font-family !default;
$f-heading      : $base-font-family !default;


/* ------------------------------------------------
 * Definition of Breakpoints for Media Queries
  --------------------------------------------------*/
// Container
$container-width : 1200px;

// UK Kit Breakpoints
$uk-bp-small    : 640px;
$uk-bp-medium   : 960px;
$uk-bp-large    : 1200px;
$uk-bp-xlarge   : 1600px;

// Bootstrap 4 Breakpoints
$bt-bp-small    : 576px;
$bt-bp-medium   : 768px;
$bt-bp-large    : 992px;
$bt-bp-xlarge   : 1200px;
$bt-bp-xxlarge   : 1400px;

// Pages Breackpoints
$pages-bp-small    : 480px;
$pages-bp-medium   : 768px;
$pages-bp-large    : 992px;
$pages-bp-xlarge   : 1400px;


/* ------------------------------------------------
 * Buttons
 --------------------------------------------------*/
$button-height		: 45px;
$button-min-width	: $button-height;

/* ------------------------------------------------
 * Forms
 --------------------------------------------------*/
$form-input-height		: $button-height;
$form-input-padding		: 0 17px;

