$breakpoint:            768px;
$offcanvas-bar-width:   270px;
$offcanvas-bar-width-m: 560px;

.rp-offcanvas-wide {
    .uk-offcanvas-bar {
        background: #fff;
        color: $c-black;
        left: (-$offcanvas-bar-width);
        width: $offcanvas-bar-width;

        h3 {
            padding-right: 45px;
            font-family: $base-font-family-bold;
            color: $c-black;
            //letter-spacing: -2px;
        }

        hr {
            border-top-color: #e6e6e6;
        }

        .uk-close {
            color: $c-black-light;
        }
    }

    @media (min-width: $breakpoint) {
        .uk-offcanvas-bar {
            left: (-$offcanvas-bar-width-m);
            width: $offcanvas-bar-width-m;
        }

    }

    .uk-open > .uk-offcanvas-reveal { width: $offcanvas-bar-width; }

    @media (min-width: $breakpoint) {
        .uk-open > .uk-offcanvas-reveal { width: $offcanvas-bar-width-m; }
    }

    :not(.uk-offcanvas-flip).uk-offcanvas-container-animation { left: $offcanvas-bar-width; }

    .uk-offcanvas-flip.uk-offcanvas-container-animation { left: (-$offcanvas-bar-width); }

    @media (min-width: $breakpoint) {
        :not(.uk-offcanvas-flip).uk-offcanvas-container-animation { left: $offcanvas-bar-width-m; }

        .uk-offcanvas-flip.uk-offcanvas-container-animation { left: (-$offcanvas-bar-width-m); }
    }
}

.uk-offcanvas-flip {
    .rp-offcanvas-wide {
        .uk-offcanvas-bar {
            left: auto;
            right: (-$offcanvas-bar-width);
        }

        @media (min-width: $breakpoint) {
            .uk-offcanvas-bar { right: (-$offcanvas-bar-width-m); }
        }

        &.uk-open > .uk-offcanvas-bar {
            left: auto;
            right: 0;
        }
    }
}
