#construction-tasks {
    margin: {
        top: 20px;
        bottom: 25px;
    }
    background-color: $c-white;

    ul.uk-list > li {
        min-height: 53px;

        &:nth-of-type(1) {
            min-height: 52px;
        }
    }
}

#construction-task-static-part {
    position: relative;
    z-index: 2;
    background-color: $c-white;
    width: auto;
    max-width: 400px; //500px; //35%;

    .construction-task {
        .sortable-task-handle {
            position: relative;
            left: -5px;
            top: -5px;
            margin-right: 5px;
        }
    }
}

#construction-task-dynamic-part {
    position: relative;
    overflow: hidden;
}

.construction-tasks {
    &--static-title {
        display: inline-block;
        padding-right: 30px;
    }
}

[data-rp-status] {
    .form-group {
        margin-bottom: 0;
    }
}

.construction-task-status {
    min-width: 80px;
    cursor: pointer;

    &.is-locked[disabled] {
        color: inherit;
    }

    &[data-rp-status='open'] {
        border-left: 3px solid $color-present;
        background-color: rgba($color-present, 0.05);
    }

    &[data-rp-status='closed'] {
        border-left: 3px solid $color-excused;
        background-color: rgba($color-excused, 0.05);
    }
}

#offcanvas-construction-task {
    z-index: 898;
}

#offcanvas-content {
    position: relative;

    #comments-construction-task-btn {
        position: absolute;
        right: 0;
        top: -75px;
    }
}

.gantt-container {
    position: static; //relative;
    width: 100%;
    height: 100%;
    overflow: auto;

    &::before {
        content: '';

        position: absolute;
        top: 0;
        left: -1px;
        bottom: 0;
        z-index: 1;

        width: 1px;
        height: 100%;

        background-color: transparent;
        box-shadow: 1px 0 6px 0 rgba($c-black, 0.75);
    }

    &::after {
        content: '';

        position: absolute;
        top: 0;
        right: -1px;
        bottom: 0;
        z-index: 1;

        width: 1px;
        height: 100%;

        background-color: transparent;
        box-shadow: -1px 0 6px 0 rgba($c-black, 0.75);
    }

    .gantt {
        width: auto;
        max-width: none;
        height: 100%;

        .grid-header {
            stroke-width: 0;
        }

        .bar,
        .bar-progress {
            &.red {
                fill: #dc3a55;
            }
            &.orange {
                fill: #fe9441;
            }
            &.yellow {
                fill: #ffd02d;
            }
            &.violet {
                fill: #7940a1;
            }
            &.navy-blue {
                fill: #2b345d;
            }
            &.blue {
                fill: #006fcb;
            }
            &.light-blue {
                fill: #00b1fe;
            }
            &.light-green {
                fill: #00d3bd;
            }
            &.green {
                fill: #0ac37e;
            }
            &.light-grey {
                fill: #939293;
            }
            &.brown {
                fill: #965837;
            }
            &.black {
                fill: #000000;
            }
        }

        .bar:hover,
        .bar-progress:hover {
            &.red {
                fill: lighten(#dc3a55, 10%);
            }
            &.orange {
                fill: lighten(#fe9441, 10%);
            }
            &.yellow {
                fill: lighten(#ffd02d, 10%);
            }
            &.violet {
                fill: lighten(#7940a1, 10%);
            }
            &.navy-blue {
                fill: lighten(#2b345d, 10%);
            }
            &.blue {
                fill: lighten(#006fcb, 10%);
            }
            &.light-blue {
                fill: lighten(#00b1fe, 10%);
            }
            &.light-green {
                fill: lighten(#00d3bd, 10%);
            }
            &.green {
                fill: lighten(#0ac37e, 10%);
            }
            &.light-grey {
                fill: lighten(#939293, 10%);
            }
            &.brown {
                fill: lighten(#965837, 10%);
            }
            &.black {
                fill: lighten(#000000, 10%);
            }
        }

        .bar-wrapper:hover,
        .bar-wrapper.active {
            .bar,
            .bar-progress {
                &.red {
                    fill: lighten(#dc3a55, 10%);
                }
                &.orange {
                    fill: lighten(#fe9441, 10%);
                }
                &.yellow {
                    fill: lighten(#ffd02d, 10%);
                }
                &.violet {
                    fill: lighten(#7940a1, 10%);
                }
                &.navy-blue {
                    fill: lighten(#2b345d, 10%);
                }
                &.blue {
                    fill: lighten(#006fcb, 10%);
                }
                &.light-blue {
                    fill: lighten(#00b1fe, 10%);
                }
                &.light-green {
                    fill: lighten(#00d3bd, 10%);
                }
                &.green {
                    fill: lighten(#0ac37e, 10%);
                }
                &.light-grey {
                    fill: lighten(#939293, 10%);
                }
                &.brown {
                    fill: lighten(#965837, 10%);
                }
                &.black {
                    fill: lighten(#000000, 10%);
                }
            }
        }

        .bar-wrapper {
            &.gantt-level-0 {
                .bar,
                .bar-progress {
                    &.red {
                        fill: #dc3a55;
                    }
                    &.orange {
                        fill: #fe9441;
                    }
                    &.yellow {
                        fill: #ffd02d;
                    }
                    &.violet {
                        fill: #7940a1;
                    }
                    &.navy-blue {
                        fill: #2b345d;
                    }
                    &.blue {
                        fill: #006fcb;
                    }
                    &.light-blue {
                        fill: #00b1fe;
                    }
                    &.light-green {
                        fill: #00d3bd;
                    }
                    &.green {
                        fill: #0ac37e;
                    }
                    &.light-grey {
                        fill: #939293;
                    }
                    &.brown {
                        fill: #965837;
                    }
                    &.black {
                        fill: #000000;
                    }
                }
            }
            &.gantt-level-1 {
                .bar,
                .bar-progress {
                    &.red {
                        fill: lighten(#dc3a55, 20%);
                    }
                    &.orange {
                        fill: lighten(#fe9441, 20%);
                    }
                    &.yellow {
                        fill: lighten(#ffd02d, 20%);
                    }
                    &.violet {
                        fill: lighten(#7940a1, 20%);
                    }
                    &.navy-blue {
                        fill: lighten(#2b345d, 20%);
                    }
                    &.blue {
                        fill: lighten(#006fcb, 20%);
                    }
                    &.light-blue {
                        fill: lighten(#00b1fe, 20%);
                    }
                    &.light-green {
                        fill: lighten(#00d3bd, 20%);
                    }
                    &.green {
                        fill: lighten(#0ac37e, 20%);
                    }
                    &.light-grey {
                        fill: lighten(#939293, 20%);
                    }
                    &.brown {
                        fill: lighten(#965837, 20%);
                    }
                    &.black {
                        fill: lighten(#000000, 20%);
                    }
                }
            }
            &.gantt-level-2 {
                .bar,
                .bar-progress {
                    &.red {
                        fill: lighten(#dc3a55, 40%);
                    }
                    &.orange {
                        fill: lighten(#fe9441, 40%);
                    }
                    &.yellow {
                        fill: lighten(#ffd02d, 40%);
                    }
                    &.violet {
                        fill: lighten(#7940a1, 40%);
                    }
                    &.navy-blue {
                        fill: lighten(#2b345d, 40%);
                    }
                    &.blue {
                        fill: lighten(#006fcb, 40%);
                    }
                    &.light-blue {
                        fill: lighten(#00b1fe, 40%);
                    }
                    &.light-green {
                        fill: lighten(#00d3bd, 40%);
                    }
                    &.green {
                        fill: lighten(#0ac37e, 40%);
                    }
                    &.light-grey {
                        fill: lighten(#939293, 40%);
                    }
                    &.brown {
                        fill: lighten(#965837, 40%);
                    }
                    &.black {
                        fill: lighten(#000000, 40%);
                    }
                }

                .bar-label {
                    &.red {
                        color: $c-gray-dark;
                    }

                    &.orange {
                        color: $c-gray-dark;
                    }

                    &.yellow {
                        color: $c-gray-dark;
                    }

                    &.light-blue {
                        color: $c-gray-dark;
                    }

                    &.light-green {
                        color: $c-gray-dark;
                    }

                    &.green {
                        color: $c-gray-dark;
                    }

                    &.light-grey {
                        color: $c-gray-dark;
                    }

                }
            }
        }

    }

    .popup-wrapper .title {
        border-bottom: 3px solid $c-gray-dark;
    }
}

.construction-task-dependency-form {
    input, select, .select2 {
        display: inline-block !important;
    }

    input[type=number] {
        width: 100px;
    }

    select.full-width + .select2-container {
        width: 220px !important;
        margin-top: 5px;
    }
}

#gantt-view-mode {
    display: inline-block;
    width: 100px;
    margin-right: 10px;
}

.construction-task-template {
    .card-title {
        position: relative;

        .sortable-construction-task-template-handle {
            position: absolute;
            left: 0;
            top: 36%;
        }

        .form-group {
            display: inline-block;
            margin-left: 20px;
        }

        .construction-task-template-dependency-form {
            .form-control {
                display: inline-block;
            }

            .construction-task-depend-on-days-between {
                max-width: 80px;
            }

            .construction-task-depend-on-id {
                max-width: 150px;
            }
        }
    }
}

#construction-task-template {
    position: relative;
    display: block;
    height: 100%;
    min-height: 50px;
    border: 2px dashed #9c9c9c;
    border-radius: 5px;
    background-color: #e3e3e3;
    text-align: center;
    color: $c-gray-dark;
    opacity: 0.45;
}
