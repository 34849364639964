
.horizontal-app-menu .header,
.header {
    padding: 10px 15px 10px 15px;
    background-color: $c-black; /*fallback for older browser*/
    background-color: var(--main-header-bg-color);

    @include min-screen($pages-bp-large) {
        padding: 10px 0 0 0;
    }

    body.is-admin & {
        border-bottom: 1px solid $c-gray-light;

        @include min-screen($pages-bp-large) {
            background-color: $c-white;
            padding-top: 0;
            min-height: 60px;
        }

        .title-bar {
            img.#{$namespace}icon-project {
                opacity: 0.5;
            }

        }

        // Menu Admin in Mobile sidebar
        .menu-bar {
            display: none;

            @include max-screen($pages-bp-large) {
                display: flex;
                flex-direction: column;
                margin-bottom: 0;
            }

            .secondary-sidebar {
                display: block !important;
                position: relative;
                top: auto;
                left: auto;
                right: auto;
                width: 100%;
                order: 2;
                z-index: 1;
                background-color: transparent;

                padding: 0;
                border-radius: 0;


                .title-bar {
                    display: none;
                }

                .main-menu {
                    padding: 0;
                }

            }

        }

        // Header Admin on Mobile
        .#{$namespace}header-project-switch {
            @include max-screen($pages-bp-large) {
                display: none;
                visibility: hidden;
            }
        }

    }

    .toggle-sidebar {
        color: $c-white;
        font-size: 24px;
    }

    .header-lg-height {
        height: auto;
    }

    .header-inner {
        //position: relative;
        min-height: 60px;
        height: auto;


        @include max-screen($pages-bp-large) {
            min-height: initial;
        }

        .brand.inline {
            border-right: 0 none;
            //border-right-color: $c-white;
            padding-top: 10px; //15px;
            padding-bottom: 10px;

            @include max-screen($pages-bp-large) {
                position: absolute;
                left: 50%;
                top: 50%;

                border: 0 !important;
                padding: 0 !important;

                transform: translate(-50%, -50%);

            }

            img {
                max-height: 65px; //75px;

                @include max-screen($pages-bp-large) {
                    max-height: 35px;
                }
            }



        }
    }



    .title-bar {
        padding-bottom: 0;

        .page-title {
            position: relative;
            display: inline-block;
            box-sizing: border-box;
            top: 5px;
            //padding-bottom: 5px;
            color: $c-white;

            @include max-screen($pages-bp-large) {
                display: none;
            }
        }
    }

    .header-icon {
        position: relative;

        font-size: 20px;
        //padding-bottom: 5px;
        margin: {
            left: 7px;
            right: 7px;
        }

        color: $c-white;

        &.fa-bell-o {
            color: $c-white;

        }

        &.fa-bell {
            color: $c-white;
        }

        &.swing {
          @extend %animate-swing;
        }
    }

    .#{$namespace}username {
        & > a {
            color: $c-gray-lighter-alt;

            &:hover {
                color: $c-white;
            }
        }

    }

    .bubble {
        width: 18px;
        height: 18px;


        background-color: $c-red;
        padding: 0;

        font: {
            family: $base-font-family-bold; //arial, sans-serif;
            size: 10px;
            //weight: bold;
        }
        text-align: center;
        line-height: 19px;
        letter-spacing: normal;
    }
}
.horizontal-app-menu .header .header-main-nav,
.header-main-nav {
    margin-top: 25px;
    //background-color: rgba($c-white, 0.2);

    @include max-screen($pages-bp-large) {
        margin-top: 0;
    }

    &--search {
        /*position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
*/
        margin-right: 25px;
        color: $c-gray-lighter-alt;

        .pg-search {
            color: $c-gray-lighter-alt;
        }
    }

}

.#{$namespace}switch-project {
    order: 1;
    padding: 65px 10px 10px 10px;
    margin-bottom: 15px;
    background-color: $c-black; /*fallback for older browser*/
    background-color: var(--main-header-bg-color);

    @include min-screen($pages-bp-large) {
        position: absolute;
        //top: 50%;
        right: 15px;
        z-index: 1;
        width: auto;

        background: transparent;
        padding: 0;
        margin-bottom: initial;
        order: 2;
    }

    .#{$namespace}brand-logo {
        position: absolute;
        top: 10px;
        left: 10px;

        img {
            max-height: 40px;
            max-width: 215px;
        }

        @include min-screen($pages-bp-large) {
            display: none;
            visibility: hidden;
        }
    }



    .dropdown-default {
        display: block;

        @include min-screen($pages-bp-large) {
            display: inline-block;
        }
    }

    .dropdown-toggle {
        //padding-top: 10px;
        font-size: 12px;
        background: $c-white;
        border-color: transparent;

        &:hover {
            border-color: transparent;
        }

        @include max-screen($pages-bp-large) {
            width: 100% !important;
        }

    }
    .dropdown-item {
        font-size: 11px;

        &:hover,
        &:focus,
        &:active
        {
            background-color: $c-beige;
            color: $c-white;
            border-radius: 0;
        }
    }

    .dropdown-menu > li:first-child,
    .dropdown-menu > .dropdown-item:first-child
    {
        padding-top: 0;
    }

    .dropdown-menu > li:last-child,
    .dropdown-menu > .dropdown-item:last-child
    {
        padding-bottom: 0;
    }
}