
body.page-profile {

    .card {
        .card-header {
            margin-bottom: 0;
            border-bottom: 0;
        }
        .card-block {
            padding-top: 15px;
            border-top: 1px solid $c-gray-lighter;

        }
    }

    form {
        .card {
            margin-top: 30px;

            .rp-avatar-picture {
                position: relative;
                margin: 0;
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
            }
        }
    }
}
