.#{$namespace}accordion-item {
    background-color: $c-white;

    .uk-accordion-title {
        position: relative;
        background-color: $c-white; //$c-gray-light;
        padding: 15px 60px 15px 25px;
        vertical-align: middle;

        &:hover {
            background-color: $c-gray-light;
        }


        &::before {
            //border: 1px solid $c-link;
            //border-radius: 50%;
            position: absolute;
            top: calc(50% - 14px);
            right: 15px;

            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='20' height='20' xmlns='http://www.w3.org/2000/svg' data-svg='chevron-down'%3E%3Cpath fill='none' stroke='%23000' stroke-width='1.03' d='M16 7l-6 6-6-6'/%3E%3C/svg%3E");
            transform: rotate(0deg);
            transition: transform 0.25s;
            float: none;
        }

        .#{$namespace}granted-label {
            box-sizing: border-box;
            position: relative;
            display: inline-block;
            vertical-align: middle;
            margin: 0 {
                left: 20px;
            }

            background-color: $c-black;
            padding: 0 10px 0 40px;
            height: 28px;
            line-height: 30px;
            border-radius: 14px; //3px;
            overflow: hidden;


            //color: $c-white;
            font : {
                size: 13px;
            }

            & + .#{$namespace}granted-label {
                margin-left: 5px;
            }

            img, svg {
                content: '';
                position: absolute;
                top: 0;
                left: 7px;
                bottom: 0;

                display: inline-block;
                width: 30px;
                height: 100%;
                padding: 4px;

                //background-color: $c-black;

            }

            &.is-customer {
                background-color: $color-customer;
                img, svg {
                    //background-color: darken($color-customer, 10%);
                }
            }

            &.is-contractor {
                background-color: $color-contractor;
                img, svg {
                    //background-color: darken($color-contractor, 10%);
                }
            }
        }
    }

    .uk-accordion-content {
        margin-top: 0;
        padding: 20px;
    }

    &.uk-open {
        .uk-accordion-title {
            background-color: $c-white; //$c-gray;
            color: $c-black; //$c-white;

            &::before {
                //color: $c-white;
                //border-color: transparent;
                //background-color: $c-white;
                //border-radius: 50%;
                transform: rotate(-180deg);
            }

        }
    }


    &.is-light {
        .uk-accordion-title {
            //background-color: $c-gray-light;
            //color: $c-gray;
        }

        &.uk-open {
            .uk-accordion-title {
                //background-color: $c-gray-light;
            }
        }

    }

}

